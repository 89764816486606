import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { AppState } from "../../../store";
import { HandoffResponse, SessionConf } from "../../../types";
import { Spinner } from "react-bootstrap";
import { QRGenerator } from "../../../components/qr-code";
import { REACT_APP_HAND_OFF_URL_BASE } from "../../../config";
import { FORM_STEP, TEXT } from "const";
import { IconCheck } from "components/icons/check";
import { api, t } from "../../../utils";
import Joi from "@hapi/joi";

export const fetchHandoverId = (
  sessionConf: SessionConf,
  instanceId: string,
  preferredStep?: string
): Promise<HandoffResponse | null> => {
  if (sessionConf === null) {
    throw new Error("Session must be defined.");
  }
  return api
    .post<HandoffResponse>(
      FORM_STEP.KYC_VIEW,
      `/api/v1/session/${sessionConf.sessionId}/handoff/${instanceId}`,
      {
        preferred_step: preferredStep,
      },
      Joi.any()
    )
    .then((result) => {
      if (result.success) {
        return result.payload;
      }
      return null;
    })
    .catch(() => {
      // surpress error
      return null;
    });
};
export const checkHandoverId = (
  sessionConf: SessionConf,
  instanceId: string
): Promise<Array<HandoffResponse> | null> => {
  if (sessionConf === null) {
    throw new Error("Session must be defined.");
  }
  return api
    .get<Array<HandoffResponse>>(
      FORM_STEP.KYC_VIEW,
      `/api/v1/session/${sessionConf.sessionId}/handoff/${instanceId}`,
      {},
      Joi.any()
    )
    .then((result) => {
      if (result.success) {
        return result.payload;
      }
      return null;
    });
};

interface HandoffQrPRops {
  sessionConf: SessionConf;
  preferredStep?: FORM_STEP;
}
const refreshHandoffToken = (
  sessionConf: SessionConf,
  setHandoff: (h: HandoffResponse) => void,
  instanceId: string,
  preferredStep?: string
) => {
  fetchHandoverId(sessionConf, instanceId, preferredStep).then((handoff) => {
    if (handoff !== null) {
      const ttl = handoff.expires_at - handoff.created_at;
      setTimeout(
        () =>
          refreshHandoffToken(
            sessionConf,
            setHandoff,
            instanceId,
            preferredStep
          ),
        ttl / 2
      );
      setHandoff(handoff);
    }
  });
};
export const HandoffQrInner = (props: HandoffQrPRops) => {
  const [handoff, setHandoff] = useState<HandoffResponse | null>(null);
  const [consumed, setConsumed] = useState<HandoffResponse | null>(null);
  const [instanceId] = useState(Date.now().toString());
  const { sessionConf, preferredStep } = props;

  useEffect(() => {
    refreshHandoffToken(sessionConf, setHandoff, instanceId, preferredStep);
    const interval = setInterval(async () => {
      const consumed = await checkHandoverId(sessionConf, instanceId);
      if (consumed !== null && consumed.length > 0) {
        setConsumed(consumed[0]);
        clearInterval(interval);
      }
    }, 20000);

    return function cleanup() {
      clearInterval(interval);
    };
  }, [sessionConf, preferredStep, instanceId, setHandoff]);
  if (consumed !== null) {
    return <IconCheck />;
  }
  if (handoff === null) {
    return <Spinner animation="border" />;
  }
  return (
    <div className="kyc-intro">
      <h4>{t(TEXT.KYC_QR_HANDOFF_HEADING)}</h4>
      <QRGenerator url={`${REACT_APP_HAND_OFF_URL_BASE}/?h=${handoff.id}`} />
    </div>
  );
};

const mapStateToProps = (state: any) => {
  const { session } = state as AppState;
  if (session.current === null) {
    throw new Error("sessionDetails must be defined");
  }
  return {
    sessionConf: {
      sessionId: session.current.session_id,
      sessionSecret: session.current.session_secret,
    },
  };
};

export const KycHandoffView = connect<any, any, any>(
  mapStateToProps,
  null
)(HandoffQrInner);
