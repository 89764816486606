import "bootstrap/dist/css/bootstrap.min.css";
import {
  FORM_STEP,
  THEME,
  PARTNER_ENVIRONMENT,
  SPECIAL_VIEW,
  LOCALSTORAGE_SESSION_ID_SUFFIX,
} from "const";
import React, { createRef } from "react";
import { connect } from "react-redux";
import { AppState, setSpecialView, resetForm } from "store";
import { RenderViewconfig, CustomStylesConfig, MODAL_VIEW } from "types";
import { ComingSoonWrapped } from "../coming-soon-view";
import { BannerView } from "../banned-view";
import { FailureView } from "../failure-view";
import { PendingView } from "../pending-view";
import { KycViewWrapped } from "../kyc-view";
import { SuccessView } from "../success-view";
import { AppFrame } from "../../components/app-frame";
import { UnderMaintenance } from "./under-maintenance";
import { UnavailableView } from "./unavailable";
import { retrieveSessionId, setDynamicHeight, t } from "utils";
import { SignInSuccessModal } from "./modals/sign-in-modal";
import classnames from "classnames";
import { MAINTENANCE_MODE } from "config";
import { KycSuccessModal } from "./modals/kyc-success-modal";
import { Loading } from "components/loading";

import "./style.scss";
import { TosView } from "containers/tos-view";
import { SingpassAgreementView } from "containers/singpass-agreement-view";
import { QuestionnaireView } from "containers/questionnaire";

export interface AppContainerProps {
  formStep: FORM_STEP;
  showModal: MODAL_VIEW | null;
  environment: PARTNER_ENVIRONMENT | null;
  theme: THEME;
  legacyFeatures: boolean;
  renderViews: RenderViewconfig;
  customStyles: CustomStylesConfig;
  underMaintenance: boolean;
  unavailable: boolean;
  showMenu: boolean;
  showSignout: boolean;
  surpress_sandbox_warning?: boolean;
  setSpecialView: (view: SPECIAL_VIEW | null) => void;
  specialView: SPECIAL_VIEW | null;
  specialLayout?: string;
  resetForm: () => void;
}

class AppContainer extends React.Component<AppContainerProps> {
  sizeTimer: NodeJS.Timer | null = null;
  containerRef = createRef<HTMLDivElement>();
  componentWillMount() {
    /**
     * hacky hack hack
     * there's no event listener for element height change :(
     */
    this.sizeTimer = setInterval(this.handleSizeTimer, 200);
  }
  componentWillUnmount() {
    if (this.sizeTimer !== null) {
      clearInterval(this.sizeTimer);
    }
  }
  lastHeight = 0;
  handleSizeTimer = () => {
    const container = this.containerRef.current;
    if (container !== null) {
      const currentHeight = container.clientHeight;
      if (this.lastHeight !== currentHeight) {
        this.lastHeight = currentHeight;
        setDynamicHeight(currentHeight);
      }
    }
  };
  renderStep(): JSX.Element {
    if (this.props.renderViews[this.props.formStep] === false) {
      // integration partner has requested this view to be blocked
      // the expectation is the parent app will destroy this iframe and render a custom view.
      return <div></div>;
    }
    switch (this.props.formStep) {
      case FORM_STEP.TOS_VIEW:
        return <TosView />;

      case FORM_STEP.KYC_VIEW:
        return <KycViewWrapped />;

      case FORM_STEP.SUCCESS_VIEW:
        return <SuccessView />;

      case FORM_STEP.BANNED_VIEW:
        return <BannerView />;
      case FORM_STEP.PENDING_VIEW:
        return <PendingView />;
      case FORM_STEP.FAILURE_VIEW:
        return <FailureView />;

      case FORM_STEP.COMING_SOON_VIEW:
        return <ComingSoonWrapped />;

      case FORM_STEP.SINGPASS_AGREEMENT_VIEW:
        return <SingpassAgreementView />;
      
      case FORM_STEP.QUESTIONNAIRE_VIEW:
        return <QuestionnaireView />;

      case FORM_STEP.LOADING_VIEW:
        return <Loading label="loading" items={[]} />;
    }
    return <div>FORM_STEP not found {this.props.formStep}</div>;
  }

  renderModal = (): JSX.Element | null => {
    const { showModal } = this.props;
    if (showModal === null) {
      return null;
    }
    switch (showModal) {
      case MODAL_VIEW.SIGNIN_SUCCESS:
        return <SignInSuccessModal />;

      case MODAL_VIEW.KYC_SUCCESS:
        return <KycSuccessModal />;
    }
    return null;
  };

  public render() {
    const {
      environment,
      legacyFeatures,
      surpress_sandbox_warning,
      specialLayout,
      formStep,
      resetForm,
    } = this.props;
    if (this.props.underMaintenance) {
      return (
        <div
          className="content-height-sensor"
          ref={this.containerRef}
          style={{
            position: "relative",
            height: legacyFeatures ? "100%" : undefined,
          }}
        >
          <UnderMaintenance />
        </div>
      );
    }
    if (this.props.unavailable) {
      return (
        <div
          className="content-height-sensor"
          ref={this.containerRef}
          style={{
            position: "relative",
            height: legacyFeatures ? "100%" : undefined,
          }}
        >
          <UnavailableView />
        </div>
      );
    }

    return (
      <div
        className={classnames({
          "top-level-wrapper": true,
          "legacy-features": legacyFeatures,
          horizontal: specialLayout === "horizontal",
        })}
      >
        <div className="content-height-sensor" ref={this.containerRef}>
          <div>
            {this.renderStep()}
            {this.renderModal()}
            {/* {environment === PARTNER_ENVIRONMENT.SANDBOX &&
            surpress_sandbox_warning !== true ? (
              <div
                style={{
                  width: "25em",
                  maxWidth: "100%",
                  margin: "20px auto",
                  textAlign: "center",
                  border: "2px solid currentColor",
                  position: "absolute",
                  bottom: "-8em",
                  left: 0,
                  right: 0,
                  padding: 7,
                  color: "#333",
                }}
              >
                <span>
                  NOTE: THIS APPLICATION IS USING A SANDBOX
                  <br />
                  API KEY AND WILL NOT PROCESS PAYMENTS
                </span>
              </div>
            ) : null} */}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  const { session, kyc } = state;

  const loggedIn =
    kyc.current !== null &&
    kyc.current.liquid_user_id !== null &&
    kyc.current.liquid_user_id !== undefined;
  const localstorage =
    window.localStorage !== undefined && window.localStorage !== null;
  const hasSession = retrieveSessionId(LOCALSTORAGE_SESSION_ID_SUFFIX) !== null;
  const isJwtAuth =
    state.config.identity !== undefined &&
    "liquid_user_jwt" in state.config.identity;
  return {
    theme: state.config.theme,
    legacyFeatures: true, // state.config.legacyFeatures,
    environment: session.current !== null ? session.current.environment : null,
    formStep: state.form.formStep,
    renderViews: state.config.renderViews,
    customStyles: state.config.custom_styles,
    underMaintenance: MAINTENANCE_MODE,
    unavailable: false,
    showMenu: loggedIn && localstorage && hasSession,
    showSignout: !isJwtAuth && loggedIn,
    showModal: state.form.showModal,
    surpress_sandbox_warning: state.config.surpress_sandbox_warning === true,
    specialView: state.form.specialView,
    specialLayout: state.config.special_layout,
  };
};

const mapDispatchToProps = (dispatch: (a: any) => void) => ({
  setSpecialView: (view: SPECIAL_VIEW | null) => dispatch(setSpecialView(view)),
  resetForm: () => dispatch(resetForm()),
});

export const App = connect(mapStateToProps, mapDispatchToProps)(AppContainer);
