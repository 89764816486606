import { CsButton } from "components/cs-button";
import React, { useState } from "react";
import { FORM_STEP, KYC_ELEMENT, TEXT } from "const";
import { useDispatch, useSelector } from "react-redux";
import { AppState, putResponse } from "store";
import { Dropdown } from "components/dropdown";
import { QUESTIONNAIRE } from "const";

import "./style.scss";
import { t } from "utils";

export const QuestionnaireView = () => {
  const [loading, setLoading] = useState(false);
  const [occupation, setOccupation] = useState("");
  const [annualIncome, setAnnualIncome] = useState("");
  const [sourceOfIncome, setSourceOfIncome] = useState("");
  const dispatch = useDispatch();
  const latestVersion = useSelector((state: AppState) => {
    if (
      state.kyc.current == undefined ||
      state.kyc.current.documents == undefined ||
      state.kyc.current.documents[KYC_ELEMENT.PDS] == undefined ||
      state.kyc.current.documents[KYC_ELEMENT.PDS].metadata === undefined
    ) {
      return "";
    }
    return state.kyc.current.documents[KYC_ELEMENT.PDS].metadata.latest_version;
  });

  const onNext = () => {
    dispatch(
      putResponse(FORM_STEP.QUESTIONNAIRE_VIEW, KYC_ELEMENT.QUESTIONNAIRE, {
        source_of_income: sourceOfIncome,
        annual_income: annualIncome,
        occupation: occupation,
      })
    );
    setLoading(true);
  };

  return (
    <div className="questionnaire">
      <h2>{t(TEXT.KYC_QUESTIANNAIRE)}</h2>
      <div>
        <div className="questionnaire-block">
          <Dropdown
            placeholder="Select..."
            label={t(TEXT.KYC_QUESTIANNAIRE_SOURCE_OF_FUND)}
            options={QUESTIONNAIRE.source_of_income}
            onChange={setSourceOfIncome}
          />
          <Dropdown
            placeholder="Select..."
            label={t(TEXT.KYC_QUESTIANNAIRE_ANNUAL_INCOME)}
            options={QUESTIONNAIRE.annual_income}
            onChange={setAnnualIncome}
          />
          <Dropdown
            placeholder="Select..."
            label={t(TEXT.KYC_QUESTIANNAIRE_OCCUPATION)}
            options={QUESTIONNAIRE.occupation}
            onChange={setOccupation}
          />
        </div>
        <footer>
          <div className="footer-content">
            <CsButton
              className="submit-btn"
              onClick={onNext}
              loading={loading}
              disabled={!occupation || !sourceOfIncome || !annualIncome}
            >
              {t(TEXT.KYC_SUBMIT)}
            </CsButton>
          </div>
        </footer>
      </div>
    </div>
  );
};
