import { FORM_STEP, SPECIAL_VIEW } from "../../const";
import { ApiResponseKycParameters, MODAL_VIEW } from "../../types";
import { DisplayError } from "../../types/display-error";


export enum FORM_TRIGGER_ACTIONS {
  FETCH_KYC_STATE = "FETCH_KYC_STATE",
  FETCH_KYC_STATE_FAILED = "FETCH_KYC_STATE_FAILED",
  RESET_SINGPASS_STATE = "RESET_SINGPASS_STATE",
  HANDOFF_FAILED = "HANDOFF_FAILED",
  HANDOFF_SUCCESS = "HANDOFF_SUCCESS",
  FETCH_USER_SESSION = "FETCH_USER_SESSION",
  HANDLE_SKIP_POA = "HANDLE_SKIP_POA",
}

export enum FORM_ACTIONS {
  PUSH_ERROR_STATE = "PUSH_ERROR_STATE",
  CLEAR_ERROR_STATE = "CLEAR_ERROR_STATE",
  RESET_FORM = "RESET_FORM",
  SET_SHOW_MODAL = "SET_SHOW_MODAL",
  DISMISS_MODAL = "DISMISS_MODAL",
  SET_FORM_STEP = "SET_FORM_STEP",
  SET_SPECIAL_VIEW = "SET_SPECIAL_VIEW",
}

export interface SetFormStep {
  type: typeof FORM_ACTIONS.SET_FORM_STEP;
  payload: {
    newStep: FORM_STEP;
    meta?: any;
  };
}

export interface ResetForm {
  type: typeof FORM_ACTIONS.RESET_FORM;
}

export interface PushErrorState {
  type: typeof FORM_ACTIONS.PUSH_ERROR_STATE;
  payload: DisplayError;
}

export interface ClearErrorState {
  type: typeof FORM_ACTIONS.CLEAR_ERROR_STATE;
  payload?: {
    code?: string; // DisplayError.code
    context?: string | null;
  };
}

export interface SetShowModal {
  type: typeof FORM_ACTIONS.SET_SHOW_MODAL;
  payload: MODAL_VIEW;
}

export interface DismissModal {
  type: typeof FORM_ACTIONS.DISMISS_MODAL;
}

export interface SetSpecialView {
  type: typeof FORM_ACTIONS.SET_SPECIAL_VIEW;
  payload: SPECIAL_VIEW | null;
}

export type FormActionTypes =
  | PushErrorState
  | ClearErrorState
  | SetFormStep
  | ResetForm
  | SetShowModal
  | DismissModal
  | SetSpecialView;

export interface FormState {
  errorState: Array<DisplayError>;
  formStep: FORM_STEP;
  showModal: MODAL_VIEW | null;
  specialView: SPECIAL_VIEW | null;
}
