import { KYC_DOCUMENT_STATUS, KYC_ELEMENT, SINGPASS_VALIDATION_STATUS } from "const";

export interface KycParameters {
  session_id: string;
}
export interface ApiResponseKycParameters {
  provider: string;
  access_code: string;
  provider_url: string;
}

export interface kycIframePostMessgeTypes {
  type: SUMSUB_EVENT_NAMES;
  payload?: any;
}

export enum SUMSUB_STATUS {
  LOADING = "LOADING",
  USER_INPUT = "USER_INPUT",
  PROCESSING = "PROCESSING",
  VERIFIED = "VERIFIED",
  REJECTED = "REJECTED",
}

export enum SUMSUB_EVENT_NAMES {
  STEP_COMPLETED = "idCheck.stepCompleted",
  APPLICANT_STATUS = "idCheck.applicantStatus",
  LIVENESS_COMPLETED = "idCheck.livenessCompleted",
  APPLICANT_SUBMITTED = "idCheck.onApplicantSubmitted",
}

export interface SumsubStatusEvent {
  status: SUMSUB_STATUS;
  meta?: any;
}

export interface KycStateDocument {
  element: KYC_ELEMENT;
  status: KYC_DOCUMENT_STATUS;
  deferrable: boolean;
  comment?: string;
  metadata?: any;
  mechanism?: string;
}

export type KycStateDocuments = { [element in KYC_ELEMENT]: KycStateDocument };

export interface SingpassFieldProps {
  label: string;
  value: string;
}

export interface SingpassDataProps {
  validation: {
    comment: SINGPASS_VALIDATION_STATUS,
    passed: boolean,
  },
  user: Array<{
    label: string;
    value: string;
    editable?: boolean;
  }>;
}

export interface questionnaireResponse {
  created_at: 1602822937513;
  id: "35675b8f-45c2-43f0-b43a-8ae4b201aaf7";
}
