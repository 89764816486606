import {
  KycActionTypes,
  KycReduxState,
  KYC_ACTIONS
} from "./types";

const initialState: KycReduxState = {
  fetchingNew: false,
  fetchingUpdated: false,
  current: null,
  kycApplicant: null,
  poaSkipped: false,
}

export const kycReducer = (
  state: KycReduxState = initialState,
  action: KycActionTypes
): KycReduxState => {
  switch (action.type) {
    case KYC_ACTIONS.REQUEST_NEW_KYC_STATE:
      return {
        ...state,
        fetchingNew: true,
        current: null
      };

    case KYC_ACTIONS.REQUEST_UPDATED_KYC_STATE:
      return {
        ...state,
        fetchingUpdated: true,
        current: null
      };

    case KYC_ACTIONS.RECEIVE_KYC_STATE:
      return {
        ...state,
        fetchingNew: false,
        fetchingUpdated: false,
        current: action.payload
      }

    case KYC_ACTIONS.RECEIVE_KYC_PARAMETERS:
      return {
        ...state,
        kycApplicant: action.payload,
      };

    case KYC_ACTIONS.SKIP_POA:
      return {
        ...state,
        poaSkipped: action.payload,
      }
    default:
      return state;
  }
};
