export const TRANSACTION_TYPE = {
  BLOCKCHAIN: "BLOCKCHAIN",
  LIQUID: "LIQUID",
  VISA_MASTER: "VISA_MASTER",
  PAYPAL: "PAYPAL",
};

export enum TRANSCATION_OUTCOME_CATEGORY {
  FILLED = "FILLED",
  ABANDONED = "ABANDONED",
  EXTERNAL_REJECT = "EXTERNAL_REJECT",
  INTERNAL_REJECT = "INTERNAL_REJECT",
  EXTERNAL_FAULT = "EXTERNAL_FAULT",
  INTERNAL_FAULT = "INTERNAL_FAULT",
}

export enum TRANSCATION_OUTCOME_REASON {
  UNSPECIFIED_ERROR = "UNSPECIFIED_ERROR",
  BLOCKCHAIN_UNEXPECTED_BROADCAST_RESPONSE = "UNEXPECTED_BLOCKCHAIN_BROADCAST_RESPONSE",
  BLOCKCHAIN_DEPOSIT_TIMEOUT = "BLOCKCHAIN_DEPOSIT_TIMEOUT",
  CARD_VELOCITY_THRESHOLD = "VELOCITY_THRESHOLD",
  CARD_ISSUER_DECLINED_TRANSACTION = "CARD_ISSUER_DECLINED_TRANSACTION",
  CARD_ISSUER_NOT_ALLOWED = "ISSUER_NOT_ALLOWED",
  CARD_ISSUER_COUNTRY_NOT_ALLOWED = "CARD_ISSUER_COUNTRY_NOT_ALLOWED",
  CARD_INSUFFICIENT_FUNDS = "INSUFFICIENT_FUNDS",
  CARD_3DS_FAILED = "CARD_3DS_FAILED",
  CARD_EXPIRED = "CARD_EXPIRED",
  CARD_CVV_FAILED = "CARD_CVV_FAILED",
  PAYOUT_COMPLETED = "PAYOUT_COMPLETED",
  USER_INPUT_TIMEOUT = "USER_INPUT_TIMEOUT",
  CARD_SCHEME_NOT_AVAILABLE = "CARD_SCHEME_NOT_AVAILABLE",
  CARD_BILLING_ADDRESS_INVALID = "CARD_BILLING_ADDRESS_INVALID",
}
