import { FORM_STEP } from "../const";

export enum SEVERITY {
  INVALID = "INVALID",
  FATAL = "FATAL",
}

export interface DisplayError {
  success?: false; // to allow quick type reduction
  message: string;
  code: string;
  severity: SEVERITY;
  context: FORM_STEP | null;
  trigger?: string;
}
