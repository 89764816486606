export enum SETTLEMENT_METHOD {
  LIQUID_WALLET = "LIQUID_WALLET",
  LIQUID_USER_WALLET = "LIQUID_USER_WALLET",
  LIQUID_PARTNER_WALLET = "LIQUID_PARTNER_WALLET",
  PAYPAL = "PAYPAL",
  BLOCKCHAIN_TRANSFER = "BLOCKCHAIN_TRANSFER",
  BLOCKCHAIN_PARTNER_WALLET = "BLOCKCHAIN_PARTNER_WALLET",
  BLOCKCHAIN_DELEGATED_BROADCAST = "BLOCKCHAIN_DELEGATED_BROADCAST",
  CARD_PAYMENT = "CARD_PAYMENT",
  GOOGLE_PAYMENT = "GOOGLE_PAYMENT",
  APPLE_PAYMENT = "APPLE_PAYMENT",
}

export enum SETTLEMENT_DIRECTION {
  FUNDING = "FUNDING",
  PAYOUT = "PAYOUT",
}
