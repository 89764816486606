import React from "react";
import { DisplayError } from "../../types";
import { CloseIcon } from "./closeIcon";
import "./style.scss";

const translate = (e: string, fallback: string): string => {
  // TODO lookup e.code
  // if not found return fallback
  return fallback;
};

interface ErrorTextProps {
  error?: DisplayError;
  message?: string;
}
export const ErrorText = (props: ErrorTextProps) => {
  const { error, message } = props;
  let translatedMessage: string | undefined;
  if (error !== undefined) {
    translatedMessage = translate(error.code, error.message);
  }
  if (message !== undefined) {
    translatedMessage = translate(message, message);
  }
  return (
    <div className={"errorMessage"}>
      {/* <CloseIcon /> */}
      {translatedMessage}
    </div>
  );
};
