import { TEXT } from "../../const";
import { WorldIcon } from "Icons";
import React from "react";
import { connect } from "react-redux";
import { resetForm } from "store/form/actions";
import { DisplayError } from "types";
import { t } from "utils/t";
import "./style.scss";
import { CsButton } from "components/cs-button";

interface ComingSoonProps {
  errorState: Array<DisplayError>;
  resetForm: () => void;
}

export const ComingSoonView = (props: ComingSoonProps) => {
  const firstError = props.errorState[0];
  return (
    <div className="comming-soon">
    </div>
  );
};

const mapStateToProps = (state: any) => {
  const errorState = state.form.errorState as Array<DisplayError>;
  return {
    errorState,
  };
};

const mapDispatchToProps = (dispatch: (a: any) => void) => {
  return {
    resetForm: () => dispatch(resetForm()),
  };
};

export const ComingSoonWrapped = connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(ComingSoonView);
