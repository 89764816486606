export enum FORM_STEP {
  TOS_VIEW = "tos",
  KYC_VIEW = "kyc",
  SINGPASS_AGREEMENT_VIEW = "singpass_agreement",
  SUCCESS_VIEW = "success",
  FAILURE_VIEW = "failure",
  BANNED_VIEW = "banned",
  PENDING_VIEW = "pending",
  COMING_SOON_VIEW = "coming-soon",
  LOADING_VIEW = "loading",
  QUESTIONNAIRE_VIEW = "questionnaire",
}

export enum SPECIAL_VIEW {
  TRANSACTION_HISTORY = "TRANSACTION_HISTORY",
}
