import { COMPLETE_STATUS } from './../const/kyc';
import { FORM_STEP } from "../const";
import { EventEmitter } from 'events';
export interface PostMessagePayload {
  event: string;
  data: any;
}

export enum POST_MESSAGES {
  SUGGESTED_SIZE = "suggested_size",
  STEP_TRANSITION = "step_transition",
  UI_EVENT = "ui_event",
  PERSIST_SESSION = "persist_session",
  DESTROY_SESSION = "destroy_session",
  ERROR = "ERROR",
  WARN = "WARN",
  TRANSACTION_CREATED = "transaction_created",
  TRANSACTION_UPDATED = "transaction_updated",
  APPROVED_KYC_FLOW = "APPROVED_KYC_FLOW",
}

export enum UI_EVENT {
  BUTTON_CLICKED = "button_clicked",
}
const eventListeners = new EventEmitter();
export const bindWidgetEventListener = (func: (str: string) => void) => {
  eventListeners.on('widget_event', func);
}
export const sendMessage = (event: POST_MESSAGES, data: any) => {
  if (window.parent !== undefined && window.parent !== null) {
    const msg: PostMessagePayload = {
      event: event,
      data: data,
    };
    eventListeners.emit('widget_event', msg);
    window.parent.postMessage(msg, "*");
  }
};

let prevContentHeight = 0;
export const setDynamicHeight = (contentHeight: number) => {
  if (prevContentHeight !== contentHeight) {
    let overflow = contentHeight + 135;
    if (overflow > 1000) {
      overflow = 1000;
    }
    suggestedSize({
      backgroundHeight: contentHeight,
      overflowHeight: overflow
    });
    prevContentHeight = contentHeight;
  }
};

export const suggestedSize = (suggested: {
  overflowHeight: number;
  backgroundHeight: number;
}) => {
  sendMessage(POST_MESSAGES.SUGGESTED_SIZE, suggested);
};

export const stepTransition = (
  new_step: FORM_STEP,
  old_step: FORM_STEP,
  formPercent: number,
  meta: any
) => {
  sendMessage(POST_MESSAGES.STEP_TRANSITION, {
    new_step,
    old_step,
    formPercent,
    meta,
  });
};

export const uiEvent = (
  ui_event: UI_EVENT,
  value: string,
  target: string,
  requestDetail?: any
) => {
  sendMessage(POST_MESSAGES.UI_EVENT, { ui_event, value, target, requestDetail });
};

export const approvedKycFlow = () => {
  sendMessage(POST_MESSAGES.APPROVED_KYC_FLOW, {});
};
