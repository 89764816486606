export enum TEXT {
  KYC_QUOTE_TITLE = "kyc-quote-title",
  KYC_QUOTE_DESC = "kyc-quote-desc",
  KYC_QUOTE_SINGPASS_SCAN = "kyc-quote-singpass-scan",
  KYC_QUOTE_SINGPASS_DESC = "kyc-quote-singpass-desc",
  KYC_QUOTE_SINGPASS_PREVIOUS_ERROR = "kyc-quote-singpass-previous-error",
  KYC_FOOTER_DESC = "kyc-footer-desc",
  KYC_ERROR_MISMATCHED_NAME = "kyc-error-mismatched-name",
  KYC_ERROR_MISSING_ADDRESS = "kyc-error-missing-addess",
  KYC_ERROR_INVALID_ADDRESS = "kyc-error-invalid-addess",
  KYC_ERROR_UNVERIFIED_ADDRESS = "kyc-error-unverified-addess",
  KYC_ERROR_INVALID_AGE_ABOVE = "kyc-error-invalid-age-above",
  KYC_ERROR_INVALID_AGE_BELOW = "kyc-error-invalid-age-below",
  KYC_ERROR_INVALID_NATIONALITY = "kyc-error-invalid-nationality",
  KYC_SINGPASS_AGREEMENT_TITLE = "kyc-singpass-agreement-title",
  KYC_AGREEMENT_ALLOW_PERMISSION = "kyc-agreement-allow-permission",
  KYC_AGREEMENT_TERM_AND_CONDITIONS = "kyc-agreement-term-and-conditions",
  KYC_TRY_AGAIN = "kyc-try-again",
  KYC_ACCEPT = "kyc-accept",
  KYC_SUCCESS_TITLE = "kyc-success-title",
  KYC_SUCCESS_DESC = "kyc-success-desc",
  KYC_CONTINUE = "kyc-continue",
  KYC_PEDNING_TITLE = "kyc-pending-title",
  KYC_PEDNING_DESC = "kyc-pending-desc",
  KYC_BACK_TO_HOME = "kyc-back-to-home",
  KYC_RETRY = "kyc-retry",
  KYC_FAILED_TITLE = "kyc-failed-title",
  KYC_QUESTIANNAIRE = "kyc-questionnaire",
  KYC_QUESTIANNAIRE_SOURCE_OF_FUND = "kyc-questionnaire-source-of-fund",
  KYC_QUESTIANNAIRE_ANNUAL_INCOME = "kyc-questionnaire-annual-income",
  KYC_QUESTIANNAIRE_OCCUPATION = "kyc-questionnaire-occupation",
  KYC_SUBMIT = "kyc-submit",
  KYC_BANNED_TITLE = "kyc-banned-title",
  KYC_BANNED_DESC = "kyc-banned-desc",
  KYC_LOADING = "kyc-loading",
  KYC_WAITING = "kyc-waiting",
  KYC_BACK = "kyc-back",
  KYC_PHONE = "kyc-phone",
  KYC_PHONE_PLACEHOLDER = "kyc-phone-placeholder",
  KYC_PHONE_INVALID = "kyc-phone-invalid",
  KYC_QR_HANDOFF_HEADING = "kyc-qr-handoff-heading",
  KYC_EMAIL = "kyc-email",
  KYC_EMAIL_PLACEHOLDER = "kyc-email-placeholder",
  KYC_EMAIL_INVALID = "kyc-email-invalid",
}
