import { CustomStylesConfig } from "types";

export const deriveCurrentColor = (custom_styles: CustomStylesConfig) =>
  custom_styles["cs-base"] !== null &&
  custom_styles["cs-base"] !== undefined &&
  custom_styles["cs-base"].color !== null &&
  custom_styles["cs-base"].color !== undefined
    ? custom_styles["cs-base"].color
    : "#333";

export const deriveBackgroundColor = (custom_styles: CustomStylesConfig) =>
  custom_styles["cs-select"] !== null &&
  custom_styles["cs-select"] !== undefined &&
  custom_styles["cs-select"].background !== null &&
  custom_styles["cs-select"].background !== undefined
    ? custom_styles["cs-select"].background
    : "#fff";

export const deriveErrorColor = (custom_styles: CustomStylesConfig) =>
  custom_styles["cs-error"] !== null &&
  custom_styles["cs-error"] !== undefined &&
  custom_styles["cs-error"].background !== null &&
  custom_styles["cs-error"].background !== undefined
    ? custom_styles["cs-error"].background
    : "rgb(204 61 17)";

export const sumsubCustomCss = (
  currentColor: string,
  backgroundColor: string,
  errorColor: string
) => `
:root{
  --white-color:#fff;
  --primary-color:${currentColor};
  --red-color:#0055ff;
  --dark-red-color:rgba(61,122,241,0.3);
  --orange-color:#0055ff;
  --gray-color:#cacce3;
  --light-grey-color:#f1f3f9;
  --success-color:#3cb371;
  --section-shadow-color:rgba(0,0,0,0.08);
  --bullet-shadow-color:#dedede;
  --step-mobile:#d9deeb;
  --facebook-color:#3b5998;
  --google-color:#dd4b39;
  --textarea-background-color:rgba(0,0,0,0.02);
}
.desktop,.mobile,.mobile-button{
  display:none!important;
}
.upload-payment-item .upload-item{
  box-shadow:none;
  border:none;
}
section.content{
  background-color:transparent;
}
input,select,body{
  background-color:transparent!important;
}

.upload-payment-item,.upload-item,.list{
  background-color:${backgroundColor}!important;
}
.upload-item .drag-drop,.loader-overlay{
  background-color:transparent !important;
}
.list li.active, .list li:hover,.error-message.warn,.error-message{
  background-color:transparent !important;
}
.ident-step .fa{
  color:#000;
}
.overlay rect{
  fill:transparent !important;
}
.sumsub-logo{
  display:none;
}
.popup{
  background:${backgroundColor}!important;
}
.fields-list a.show-hide,.fields-list input.country,.fields-list input[type="text"],.fields-list ::placeholder{
  color: ${currentColor}!important;
}
.fields-list input.country, .fields-list input[type="text"], .fields-list ::placeholder{
  border-bottom: 1px solid #fff;
}
a.show-hide{
  top:0 !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
  background-color:transparent !important;
}
input{
  height: 19.5px;
  font-size:1em;
}
.upload-item i.upload-icon{
  font-size:80px;
}
.markdown-instructions.columns-2 p:nth-child(3), .markdown-instructions.columns-2 ul:last-child li{
  color: ${errorColor};
}
`;
