import { AppState } from "store";
import { SEVERITY, StepChange } from "../types";
import {
  FORM_STEP,
  KYC_DOCUMENT_STATUS,
  KYC_ELEMENT,
  KYC_IDENTITY_STATUS,
  SINGPASS_VALIDATION_STATUS,
} from "../const";

const BROKEN_STATUS = ["BROKEN"];
const INPUT_STATUS = ["PARAMETERS_REQUIRED", "WAITING_USER"];
const WAITING_STATUS = [
  "STARTED",
  "READY",
  "WAITING_INTERNAL",
  "WAITING_EXTERNAL",
  "COMPLETE",
];

const canShowComingSoonView = (appState: AppState): boolean => {
  const { errorState } = appState.form;
  return (
    errorState.filter(
      (e) =>
        e.code === "issuer_country_rejected" ||
        e.code === "liquid_user_country_rejected"
    ).length > 0
  );
};

const canShowBannedView = (appState: AppState): boolean => {
  const { kyc, singpass } = appState;
  return (
    kyc?.current?.status === KYC_IDENTITY_STATUS.BANNED ||
    singpass?.current?.validation?.comment ===
      SINGPASS_VALIDATION_STATUS.BANNED_ACCOUNT
  );
};

const canShowFailureView = (appState: AppState): boolean => {
  const { errorState } = appState.form;
  // // No general error present
  // if (
  //   errorState.filter(
  //     (e) => e.context === undefined || e.severity === SEVERITY.FATAL
  //   ).length > 0
  // ) {
  //   console.log(errorState);
  //   return true;
  // }
  if (errorState.length) {
    return true;
  }

  return false;
};

const canShowLoadingView = (appState: AppState) => {
  const { kyc, session } = appState;
  const { errorState } = appState.form;
  if (!session.current || kyc.current === null && !errorState.length) {
    return true;
  }
  return false;
};

const canQuestionnaireView = (appState: AppState) => {
  const { kyc, singpass } = appState;
  if (kyc.current === null) {
    return false;
  }
  if (kyc.current.status === KYC_IDENTITY_STATUS.FULLY_APPROVED) {
    return false;
  }
  return (
    (kyc.current.documents[KYC_ELEMENT.QUESTIONNAIRE].status ===
      KYC_DOCUMENT_STATUS.UNSUBMITTED ||
      kyc.current.documents[KYC_ELEMENT.QUESTIONNAIRE].status ===
        KYC_DOCUMENT_STATUS.REJECTED) &&
    (kyc.current.documents[KYC_ELEMENT.POA].status ===
      KYC_DOCUMENT_STATUS.APPROVED ||
      kyc.current.documents[KYC_ELEMENT.POA].status ===
        KYC_DOCUMENT_STATUS.VERIFYING ||
      kyc.current.documents[KYC_ELEMENT.POA].status ===
        KYC_DOCUMENT_STATUS.DELAYED) &&
    (kyc.current.documents[KYC_ELEMENT.POI].status ===
      KYC_DOCUMENT_STATUS.APPROVED ||
      kyc.current.documents[KYC_ELEMENT.POI].status ===
        KYC_DOCUMENT_STATUS.VERIFYING ||
      kyc.current.documents[KYC_ELEMENT.POI].status ===
        KYC_DOCUMENT_STATUS.DELAYED) &&
    kyc.current.documents[KYC_ELEMENT.TOS].status ===
      KYC_DOCUMENT_STATUS.APPROVED
  );
};

const canSingpassAgreementView = (appState: AppState) => {
  const { singpass, kyc } = appState;

  return (
    singpass.current !== null &&
    (kyc?.current?.documents[KYC_ELEMENT.POA]?.status ===
      KYC_DOCUMENT_STATUS.UNSUBMITTED ||
      kyc?.current?.documents[KYC_ELEMENT.POI]?.status ===
        KYC_DOCUMENT_STATUS.UNSUBMITTED ||
      kyc?.current?.documents[KYC_ELEMENT.TOS]?.status ===
        KYC_DOCUMENT_STATUS.UNSUBMITTED ||
      kyc?.current?.documents[KYC_ELEMENT.PDS]?.status ===
        KYC_DOCUMENT_STATUS.UNSUBMITTED ||
      kyc?.current?.documents[KYC_ELEMENT.POA]?.status ===
        KYC_DOCUMENT_STATUS.REJECTED ||
      kyc?.current?.documents[KYC_ELEMENT.POI]?.status ===
        KYC_DOCUMENT_STATUS.REJECTED ||
      kyc?.current?.documents[KYC_ELEMENT.TOS]?.status ===
        KYC_DOCUMENT_STATUS.REJECTED ||
      kyc?.current?.documents[KYC_ELEMENT.PDS]?.status ===
        KYC_DOCUMENT_STATUS.REJECTED)
  );
};

const canShowSuccessView = (appState: AppState): boolean => {
  const { kyc } = appState;
  if (kyc.current === null) {
    return false;
  }
  return kyc.current.status === KYC_IDENTITY_STATUS.FULLY_APPROVED;
};

const canShowPendingView = (appState: AppState): boolean => {
  const { kyc } = appState;
  if (kyc.current === null) {
    return false;
  }
  return kyc.current.status === KYC_IDENTITY_STATUS.REVIEW_PENDING;
};

const canShowTos = (appState: AppState): boolean => {
  const { kyc } = appState;
  if (kyc.current === null) {
    return false;
  }
  if (kyc.current.status === KYC_IDENTITY_STATUS.FULLY_APPROVED) {
    return false;
  }
  return (
    kyc.current.documents[KYC_ELEMENT.TOS].status !==
    KYC_DOCUMENT_STATUS.APPROVED
  );
};

const canShowKyc = (appState: AppState): boolean => {
  const { kyc } = appState;

  if (kyc.current === null) {
    return false;
  }
  if (kyc.current.status === KYC_IDENTITY_STATUS.FULLY_APPROVED) {
    return false;
  }
  if (
    kyc.current.status === KYC_IDENTITY_STATUS.PARTIALLY_APPROVED &&
    kyc.poaSkipped
  ) {
    return false;
  }
  return kyc.current.status === KYC_IDENTITY_STATUS.DOCUMENTS_REQUIRED;
};

const FORM_PRIORITY_CHECK: {
  [step in FORM_STEP]: (appState: AppState) => boolean;
} = {
  [FORM_STEP.SUCCESS_VIEW]: canShowSuccessView,
  [FORM_STEP.LOADING_VIEW]: canShowLoadingView,
  [FORM_STEP.PENDING_VIEW]: canShowPendingView,
  [FORM_STEP.BANNED_VIEW]: canShowBannedView,
  [FORM_STEP.COMING_SOON_VIEW]: canShowComingSoonView,
  [FORM_STEP.FAILURE_VIEW]: canShowFailureView,
  [FORM_STEP.SINGPASS_AGREEMENT_VIEW]: canSingpassAgreementView,
  [FORM_STEP.QUESTIONNAIRE_VIEW]: canQuestionnaireView,
  [FORM_STEP.KYC_VIEW]: canShowKyc,
  [FORM_STEP.TOS_VIEW]: canShowTos,

  [FORM_STEP.KYC_VIEW]: () => true,
};
const ALL_STEPS = Object.keys(FORM_PRIORITY_CHECK) as Array<FORM_STEP>;
const NORMAL_FLOW = [FORM_STEP.KYC_VIEW, FORM_STEP.SUCCESS_VIEW];
export const getFormPercent = (step: FORM_STEP) => {
  const i = NORMAL_FLOW.indexOf(step) + 1;
  return Math.floor((i * 100) / NORMAL_FLOW.length);
};
export const getNextStep = (
  appState: AppState,
  trigger: string,
  triggerMeta?: any,
  preferredStep?: FORM_STEP
): StepChange => {
  if (preferredStep !== undefined) {
    const canShow = FORM_PRIORITY_CHECK[preferredStep];
    if (canShow !== undefined) {
      if (canShow(appState)) {
        return { trigger, triggerMeta, step: preferredStep };
      } else {
        console.error(
          "Could not set FORM_STEP: " + preferredStep,
          canShow,
          appState
        );
      }
    }
  }

  for (const step of ALL_STEPS) {
    if (FORM_PRIORITY_CHECK[step](appState)) {
      return { trigger, triggerMeta, step };
    }
  }

  throw new Error("¯\\_(ツ)_/¯");
};

export const getBackStep = (
  trigger: string,
  currentStep: FORM_STEP,
  triggerMeta?: any
): StepChange | null => {
  return null;
};

export const randStr = (length: number) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};
