import "babel-polyfill";
import { App } from "containers/app";
import "scss/index.scss";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import {
  formBack,
  fetchSessionFromHandover,
  pushErrorState,
  fetchSessionFromCorrelationKey,
  fetchUserSession,
  fetchKycState,
  resetSingpass,
} from "./store";
import { SEVERITY } from "types";
import {
  fetchTranslations,
  getBrowserLocale,
  normalizeLanguage,
  api,
  bindWidgetEventListener,
  setLang,
  retrieveSessionId,
  randStr,
  storeRedirect,
} from "utils";
import { history } from "utils/history";
import { store } from "./store";
import { fetchSingpassCache } from "store/singpass";
import { LOCALSTORAGE_SESSION_ID_SUFFIX } from "const";

api.setStore(store);
const urlParams = new URLSearchParams(window.location.search);

let isBound = false;
let isFormStateReady = false;

history.listen((a: any, action: any) => {
  if (action === "POP") {
    store.dispatch(formBack("BROWSER_BACK_HANDLER") as any);
  }
});

async function getSession() {
  /**
   * Prefer session from handoff if available
   */
  const handoffId = urlParams.get("h");
  if (handoffId !== null) {
    try {
      const session = await store.dispatch(
        fetchSessionFromHandover(null, handoffId) as any
      );
      if (session !== undefined) {
        return session;
      }
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Prefer session from handoff if available
   */
  let public_api_key = urlParams.get("pk");
  let correlation_key = urlParams.get("ck") || "test_" + randStr(20);
  let objective = urlParams.get("o");
  if (
    public_api_key !== null &&
    correlation_key !== null &&
    objective !== null
  ) {
    try {
      const session = await store.dispatch(
        fetchSessionFromCorrelationKey(
          null,
          public_api_key,
          correlation_key,
          objective
        ) as any
      );
      if (session !== undefined) {
        return session;
      }
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Next prefer user session stored in application storage, provided development url params aren't used
   */
  const sessionStore = retrieveSessionId(LOCALSTORAGE_SESSION_ID_SUFFIX);
  let url_session = urlParams.get("s");
  if (
    sessionStore !== null &&
    sessionStore.session_secret !== undefined &&
    (sessionStore.session_id === url_session)
  ) {
    try {
      api.setSessionSecret(sessionStore);
      const sessionResponse = await store.dispatch(
        fetchUserSession(null, sessionStore.session_id) as any
      );
      if (sessionResponse.success === true) {
        return sessionResponse.payload;
      }
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * In development, just create a session if none exists
   */
  if (process.env["REACT_APP_ENVIRONMENT"] === "DEVELOPMENT") {
    if (public_api_key === null) {
      public_api_key = process.env["REACT_APP_PUBLIC_API_KEY"] || null;
    }
    if (correlation_key === null) {
      correlation_key = "test_" + randStr(20);
    }
    if (objective === null) {
      objective = process.env["REACT_APP_OBJECTIVE"] || null;
    }

    console.log("developement", {
      public_api_key,
      correlation_key,
      objective,
    });
    
    if (
      public_api_key !== null &&
      correlation_key !== null &&
      objective !== null
    ) {
      try {
        const session = await store.dispatch(
          fetchSessionFromCorrelationKey(
            null,
            public_api_key,
            correlation_key,
            objective
          ) as any
        );
        if (session !== undefined) {
          return session.payload;
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  /**
   * Finally, if not able to restore a session, then create a new one.
   */
  throw new Error("Must be initialised with a handoff or correlation key");
}

window.onerror = (message, source, lineno, colno, error) => {
  store.dispatch(
    pushErrorState({
      code: "unhandled_exception",
      message: message.toString(),
      context: null,
      severity: SEVERITY.FATAL,
      trigger: `${source}:${lineno}:${colno}`,
    })
  );
};

async function bindReactDom() {
  setInterval(() => store.dispatch(fetchKycState() as any), 2000);
  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById("root")
  );
}

window.onWidgetEvent = bindWidgetEventListener;
const lang = normalizeLanguage(getBrowserLocale());
setLang(lang);

window.addEventListener('popstate', async function(event) {
  try {
    await store.dispatch(
      resetSingpass(null) as any
    );
  } catch (error) {
    console.log('###error', error);
  }
});

const loadSequence = async () => {
  if (window && window.localStorage && window.localStorage.setItem) {
    const ts = Date.now().toString();
    const key = "test-ls";
    window.localStorage.setItem(key, ts);
    if (window.localStorage.getItem(key) === ts) {
      window.localStorage.removeItem(key);
      const session = await getSession();
      const redirect = urlParams.get("r");
      storeRedirect(session?.payload?.session_id, redirect);
      await Promise.all([
        fetchTranslations(lang),
        store.dispatch(fetchKycState(null) as any),
        store.dispatch(fetchSingpassCache() as any),
      ]);
      return bindReactDom();
    }
  }
  ReactDOM.render(
    <h4>Localstorage not enabled. Please enable localStorage and reload</h4>,
    document.getElementById("root")
  );
};

loadSequence();
