import React, { FunctionComponent, ReactNode } from "react";
import { CloseIcon } from "Icons";
import "./style.scss";
import classnames from "classnames";

export interface ModalInnerProps {
  icon: JSX.Element | null;
  headingText: JSX.Element | string;
  headingColor?: string;
  onClose: () => void;
  className?: string;
  children?: ReactNode;
}

export const Modal: FunctionComponent<ModalInnerProps> = (props) => {
  const { onClose, headingText, headingColor, children, icon, className } =
    props;
  return (
    <div className={classnames("custom-modal", className)}>
      <div className="backdrop" onClick={onClose}></div>
      <div className="custom-modal-body cs-select">
        <div onClick={onClose} className="close-icon">
          <CloseIcon />
        </div>
        <div className="custom-modal-body-vcenter">
          {icon}
          {typeof headingText === "string" ? (
            <h3 className="heading" style={{ color: headingColor }}>
              {headingText}
            </h3>
          ) : (
            headingText
          )}

          <div className="description cs-subtext">{children}</div>
        </div>
      </div>
    </div>
  );
};
