import React, { useEffect } from "react";
import "./style.scss";
// import { CsButton } from "components/cs-button";
import snsWebSdk from "@sumsub/websdk";
import {
  ApiResponseKycParameters,
  SumsubStatusEvent,
  SUMSUB_EVENT_NAMES,
  SUMSUB_STATUS,
} from "types";
import { getLang } from "utils";

/**
 * @param apiUrl - 'https://test-api.sumsub.com' (sandbox)
                    or 'https://api.sumsub.com' (production)
 * @param flowName - the flow name chosen at Step 1 (e.g. 'basic-kyc')
 * @param accessToken - access token that you generated on the backend in Step 2
 * @param applicantEmail - applicant email
 * @param applicantPhone - applicant phone, if available
 */
function launchWebSdk(
  apiUrl: string,
  language: string,
  accessToken: string,
  applicantEmail: string,
  applicantPhone: string,
  setkycIframeMessage: (message: kycIframePostMessgeTypes) => void,
  applicantCountry: string,
  customCssStr?: string
) {
  let snsWebSdkInstance = snsWebSdk
    .Builder(apiUrl)
    .withAccessToken(accessToken, (newAccessTokenCallback: any) => {
      // Access token expired
      // get a new one and pass it to the callback to re-initiate the WebSDK
      let newAccessToken = "..."; // get a new token from your backend
      newAccessTokenCallback(newAccessToken);
    })
    .withConf({
      lang: language,
      email: applicantEmail,
      phone: applicantPhone,
      country: applicantCountry,
      onMessage: (type: any, payload: any) => {
        // see below what kind of messages the WebSDK generates
        // console.log("WebSDK onMessage", type, payload);
        // console.log(type, payload);
        setkycIframeMessage({ type, payload });
        if (
          payload.reviewViewResult &&
          payload.reviewViewResult.reviewAnswer &&
          payload.reviewViewResult.reviewAnswer === "green"
        ) {
          // console.log("DO SOMETHING", payload.reviewViewResult);
        }
      },
      uiConf: {
        customCssStr,
      },
      onError: (error: any) => {
        // console.error("WebSDK onError", error);
      },
    })
    .build();

  // you are ready to go:
  // just launch the WebSDK by providing the container element for it
  snsWebSdkInstance.launch("#sumsub-websdk-container");
}

interface KycCollectViewProps {
  kycApplicant: ApiResponseKycParameters;
  onSumsubEvent: (statusEvent: SumsubStatusEvent) => void;
  applicantCountry: string;
  customCssStr?: string;
}

export interface kycIframePostMessgeTypes {
  type?: any;
  payload?: any;
}

export const KycCollectView = (props: KycCollectViewProps) => {
  const { kycApplicant, applicantCountry, customCssStr, onSumsubEvent } = props;
  const { provider_url, access_code } = kycApplicant;

  useEffect(() => {
    const handleSumsubEvent = (kycIframeMessage: kycIframePostMessgeTypes) => {
      /**
       * See sample of possible sumsub events in `/sumsub-events-sample.json`
       * @param kycIframeMessage
       */

      if (kycIframeMessage.type) {
        const { type, payload } = kycIframeMessage;
        if (
          type === SUMSUB_EVENT_NAMES.APPLICANT_STATUS &&
          payload.reviewStatus === "completed" &&
          (payload.reviewResult === "GREEN" ||
            (payload.reviewResult &&
              payload.reviewResult.reviewAnswer === "GREEN"))
        ) {
          onSumsubEvent({
            status: SUMSUB_STATUS.VERIFIED,
            meta: payload,
          });
        }
      }
    };
    launchWebSdk(
      provider_url,
      getLang(),
      access_code,
      "",
      "",
      handleSumsubEvent,
      applicantCountry,
      customCssStr
    );
  }, [
    provider_url,
    access_code,
    applicantCountry,
    customCssStr,
    onSumsubEvent,
  ]);

  return (
    <div className="sumsub">
      <div id="sumsub-websdk-container"></div>
    </div>
  );
};
