export enum SINGPASS_VALIDATION_STATUS {
  UNVERIFIED_ADDRESS = "unverified_address",
  INVALID_ADDRESS = "invalid_address",
  MISMATCHED_NAME = "mismatched_name",
  MISSING_ADDRESS = "missing_address",
  BANNED_ACCOUNT = "banned_account",
  VERIFYING_ACCOUNT = "verifying_account",
  INVALID_AGE = "invalid_age",
  INVALID_NATIONALITY = "invalid_nationality",
  INTERNAL_ERROR = "internal_error",
}
