import { THEME, THEMES } from "../../const";
import {
  ConfigurationActionTypes,
  ConfigurationState,
  CONFIGURATION_ACTIONS,
} from "./types";
import { CustomStylesConfig } from "types";

const initialState: ConfigurationState = {
  theme: THEME.DEFAULT,
  allow_trade: true,
  legacyFeatures: true,
  verboseLogging: false,
  user_locale: "en",
  renderViews: {},
  custom_styles: THEMES[THEME.DEFAULT],
  external_session: false,
};

const mergeStyles = (
  theme?: THEME,
  payloadStyles?: CustomStylesConfig,
  stateStyles?: CustomStylesConfig
) => {
  if (theme !== undefined) {
    return {
      ...THEMES[theme],
      ...payloadStyles,
    };
  }
  return {
    ...stateStyles,
    ...payloadStyles,
  };
};

export const configReducer = (
  state = initialState,
  action: ConfigurationActionTypes
): ConfigurationState => {
  switch (action.type) {
    case CONFIGURATION_ACTIONS.SET_CONFIGURATION:
      return {
        ...state,
        ...action.payload,
      };
    case CONFIGURATION_ACTIONS.SET_MINIMAL_CONFIG:
      return {
        ...state,
        publicApiKey: action.payload.public_api_key,
        legacyFeatures:
          action.payload.legacyFeatures !== undefined
            ? action.payload.legacyFeatures
            : state.legacyFeatures,
        theme:
          action.payload.theme !== undefined
            ? action.payload.theme
            : state.theme,
        custom_styles: mergeStyles(
          action.payload.theme,
          action.payload.custom_styles,
          state.custom_styles
        ),
        renderViews: { ...state.renderViews, ...action.payload.render_views },
        verboseLogging:
          action.payload.verbose_logging !== undefined
            ? action.payload.verbose_logging
            : state.verboseLogging,
        identity: action.payload.identity,
        user_locale:
          action.payload.user_locale !== undefined
            ? action.payload.user_locale
            : state.user_locale,
        surpress_sandbox_warning:
          action.payload.surpress_sandbox_warning !== undefined
            ? action.payload.surpress_sandbox_warning
            : state.surpress_sandbox_warning,
        initialization_payload_raw: action.payload,
        special_layout: action.payload.special_layout,
        external_session: action.payload.identity !== undefined,
        intent_id: action.payload.intent_id,
        partner_order_id: action.payload.partner_order_id,
        partner_ref: action.payload.partner_ref,
        partner_tags: action.payload.partner_tags,
        affiliate_id: action.payload.affiliate_id,
        affiliate_ref: action.payload.affiliate_ref,
        affiliate_tags: action.payload.affiliate_tags,
      };
    case CONFIGURATION_ACTIONS.SET_CUSTOM_STYLES:
      return {
        ...state,
        custom_styles: { ...state.custom_styles, ...action.payload },
      };
    default:
      return state;
  }
};
