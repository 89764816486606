import { SessionActionTypes, SessionState, SESSION_ACTIONS } from "./types";

const defaultState: SessionState = {
  current: null,
  fetchingNew: false,
  fetchingUpdate: false,
};

export const sessionReducer = (
  state: SessionState = defaultState,
  action: SessionActionTypes
): SessionState => {
  switch (action.type) {
    case SESSION_ACTIONS.REQUEST_NEW_SESSION_DETAILS:
      return {
        ...state,
        fetchingNew: true,
        current: null,
      };

    case SESSION_ACTIONS.REQUEST_UPDATED_SESSION_DETAILS:
      return {
        ...state,
        fetchingUpdate: true,
      };

    case SESSION_ACTIONS.RECEIVE_SESSION_DETAILS:
      return {
        ...state,
        fetchingNew: false,
        fetchingUpdate: false,
        current: {
          ...action.payload,
          session_secret:
            action.payload.session_secret !== undefined
              ? action.payload.session_secret
              : state.current !== null
              ? state.current.session_secret
              : undefined,
        },
      };
    default:
      return state;
  }
};
