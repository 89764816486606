import { KYC_DOCUMENT_STATUS, KYC_IDENTITY_STATUS } from "const";
import { ApiResponseKycParameters, KycStateDocuments } from "../../types";

export enum KYC_ACTIONS {
  REQUEST_NEW_KYC_STATE = "REQUEST_NEW_KYC_STATE",
  REQUEST_UPDATED_KYC_STATE = "REQUEST_UPDATED_KYC_STATE",
  RECEIVE_KYC_STATE = "RECEIVE_KYC_STATE",
  RECEIVE_KYC_PARAMETERS = "RECEIVE_KYC_PARAMETERS",
  SKIP_POA = "SKIP_POA",
}

export interface RequestNewKycState {
  type: typeof KYC_ACTIONS.REQUEST_NEW_KYC_STATE;
}

export interface RequestUpdatedKycState {
  type: typeof KYC_ACTIONS.REQUEST_UPDATED_KYC_STATE;
}
export interface ReceiveKycState {
  type: typeof KYC_ACTIONS.RECEIVE_KYC_STATE;
  payload: KycState;
}
export interface ReceiveKycParameters {
  type: typeof KYC_ACTIONS.RECEIVE_KYC_PARAMETERS;
  payload: ApiResponseKycParameters;
}

export interface SkipPoaPayload {
  type: typeof KYC_ACTIONS.SKIP_POA;
  payload: boolean;
}

export interface KycStateCategory {
  status: KYC_DOCUMENT_STATUS;
  deferrable: boolean;
  comment?: string;
  metadata?: any;
}

export interface KycState {
  reputation_id?: string;
  kyc_identity_id?: string;
  liquid_user_id?: string;
  status: KYC_IDENTITY_STATUS;
  documents: KycStateDocuments;
  metadata?: any;
  redirect_url: string;
}

export type KycActionTypes =
  | RequestNewKycState
  | RequestUpdatedKycState
  | ReceiveKycState
  | ReceiveKycParameters
  | SkipPoaPayload;

export interface KycReduxState {
  fetchingNew: boolean;
  fetchingUpdated: boolean;
  current: KycState | null;
  kycApplicant: ApiResponseKycParameters | null;
  poaSkipped: boolean | null;
}
