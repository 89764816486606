export enum KYC_IDENTITY_STATUS {
  DOCUMENTS_REQUIRED = "DOCUMENTS_REQUIRED",
  REVIEW_PENDING = "REVIEW_PENDING",
  PARTIALLY_APPROVED = "PARTIALLY_APPROVED",
  FULLY_APPROVED = "FULLY_APPROVED",
  BLACKLIST = "BLACKLIST",
  BANNED = "BANNED",
}

export enum KYC_DOCUMENT_STATUS {
  UNSUBMITTED = "UNSUBMITTED",
  REJECTED = "REJECTED",
  APPROVED = "APPROVED",
  BANNED = "BANNED",
  VERIFYING = "VERIFYING",
  DELAYED = "DELAYED",
}

export const COMPLETE_STATUS = [KYC_DOCUMENT_STATUS.APPROVED];

export enum KYC_ELEMENT {
  PDS = "PDS",
  TOS = "TOS",
  CONTACT = "CONTACT",
  LIVENESS = "LIVENESS",
  POI = "POI",
  POA = "POA",
  QUESTIONNAIRE = "QUESTIONNAIRE",
}

export const QUESTIONNAIRE = {
  occupation: [
    "Administion / Accounting / HR",
    "Arts & Design",
    "Business / Sales",
    "Consulting",
    "Education / Research",
    "Engineering / Product management",
    "Healthcare",
    "Information Technology",
    "Legal",
    "Marketing / Media & Communications",
    "Military & Protective Services",
    "Retail / Food Services / Support",
    "Skilled Labor & Manufacturing",
    "Transportation",
    "Other",
  ],
  source_of_income: [
    "Employment Income",
    "Inheritance",
    "Investment Returns",
    "Other",
    "Savings",
  ],
  annual_income: [
    "Less than 30K",
    "Between 30K to 60K",
    "Between 60K to 100K",
    "More than 100K",
  ],
};
