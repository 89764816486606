import { ApiSession, SingpassDataProps } from "../../types";

export enum SINGPASS_ACTIONS {
  REQUEST_SINGPASS_CACHE = "REQUEST_SINGPASS_CACHE",
  RECEIVE_SINGPASS_CACHE = "RECEIVE_SINGPASS_CACHE",
}

export interface RequestSingpassCache {
  type: typeof SINGPASS_ACTIONS.REQUEST_SINGPASS_CACHE;
}

export interface ReceiveSingpassCache {
  type: typeof SINGPASS_ACTIONS.RECEIVE_SINGPASS_CACHE;
  payload: SingpassDataProps;
}

export type SingpassActionTypes = RequestSingpassCache | ReceiveSingpassCache;

export interface SingpassState {
  current: SingpassDataProps | null;
  fetching: boolean;
}
