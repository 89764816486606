import { SINGPASS_ACTIONS } from "./types";
import { Dispatch, SingpassDataProps } from "types";
import { GetState, ApiSession } from '../../types';
import { api } from "utils";
import { FORM_STEP } from "const";
import Joi from "@hapi/joi";
import { formNext } from "../form/actions";
import { FORM_TRIGGER_ACTIONS } from "../form/types";

export const fetchSingpassCache = (context: FORM_STEP | null = null) => {
  return function (dispatch: Dispatch) {
    dispatch({
      type: SINGPASS_ACTIONS.REQUEST_SINGPASS_CACHE,
    });
    api
      .get<SingpassDataProps>(
        context,
        `/api/v1/singpass/cached`,
        {},
        Joi.any(),
        true,
        true,
      )
      .then((response) => {
        if (response.success === true) {
          dispatch({
            type: SINGPASS_ACTIONS.RECEIVE_SINGPASS_CACHE,
            payload: response.payload,
          });
        }
      });
  };
};

export const resetSingpass = (context: FORM_STEP | null = null) => {
  return (dispatch: Dispatch, getState: GetState): Promise<any> => {
    const sessionId = getState().session.current?.session_id;
    if (sessionId === undefined) {
      return Promise.resolve();
    }

    return api
      .delete<ApiSession>(
        context,
        `/api/v1/singpass/cached/reset`,
        {},
        Joi.any(),
        true,
        true,
      )
      .then((response) => {
        if (response.success === true) {
          dispatch({
            type: SINGPASS_ACTIONS.RECEIVE_SINGPASS_CACHE,
            payload: null,
          });
          dispatch(formNext(FORM_TRIGGER_ACTIONS.RESET_SINGPASS_STATE));
        }
      });
  };
};
