import React, { useState } from "react";
import { API_BASE_URL } from "config";
import { t, uiEvent, UI_EVENT } from "utils";
import SingpassLogo from "./singpass-logo.png";
import { TEXT } from "const";
import "./style.scss";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { AppState } from "store";

export interface ISingpassSignInProps {
  onClick: () => Promise<void>;
}

export const SingpassSignIn = (props: ISingpassSignInProps) => {
  const [isSignInDisable, setSignInDisable] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const handleClick = async () => {
    setLoading(true);
    await props.onClick();
    setTimeout(() => setLoading(false), 3000);
  };

  return (
    <div className="singpass-signin ">
      <button
        className="button singpass-signin-logo "
        onClick={handleClick}
        disabled={isSignInDisable}
      >
        {loading ? (
          <Spinner animation="border" role="status" className="spinner">
            <span className="sr-only">{t(TEXT.KYC_LOADING)}...</span>
          </Spinner>
        ) : (
          <>
            <span>VERIFY WITH</span>
            <img src={SingpassLogo} alt="singpass-logo" />
          </>
        )}
      </button>
    </div>
  );
};
