export const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL ?? "https://partners.liquid.com";

export const REACT_APP_HAND_OFF_URL_BASE =
  process.env.REACT_APP_HAND_OFF_URL_BASE ?? "https://demo.partners.liquid.com";

export const MAINTENANCE_MODE =
  (process.env.REACT_APP_MAINTENANCE_MODE ?? false) === "true";

export const SELF_BASE_URL = process.env.REACT_APP_SELF_BASE_URL ?? window.origin;
