import classnames from "classnames";
import { TEXT } from "../../const";
import { IconConfirm } from "Icons/index";
import React, { useCallback } from "react";
import { t } from "utils/t";
import "./style.scss";
import { connect, useSelector } from "react-redux";
import { CsButton } from "components/cs-button";
import { resetForm } from "store/form/actions";
import { AppState } from "store";
import { IconCheck } from "components/icons/check";
import kycAvatarSuccess from "./kyc-avatar-success.png";
import { fetchRedirect, approvedKycFlow } from "utils";

export const SuccessView = () => {
  const redirectUrl = useSelector(
    (state: AppState) => state.kyc.current?.redirect_url
  );
  const sessionId = useSelector(
    (state: AppState) => state.session.current?.session_id
  );
  const redirectStorage =
    sessionId !== undefined ? fetchRedirect(sessionId) : undefined;

  const followRedirect = useCallback(() => {
    if (redirectStorage) {
      window.open(redirectStorage, "_self");
    } else if (redirectUrl) {
      window.open(redirectUrl, "_self");
    }
    approvedKycFlow();
  }, [redirectUrl, redirectStorage]);

  return (
    <div className="text-center kyc-success-block">
      <div className="logo-wrapper">
        <img src={kycAvatarSuccess} alt="singpass-logo" />
      </div>
      <h2 className="title">{t(TEXT.KYC_SUCCESS_TITLE)}</h2>
      <p>{t(TEXT.KYC_SUCCESS_DESC)}</p>
      <CsButton onClick={followRedirect}>{t(TEXT.KYC_CONTINUE)}</CsButton>
    </div>
  );
};
