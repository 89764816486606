import {
  applyMiddleware,
  compose,
  createStore,
  MiddlewareAPI,
  Reducer,
} from "redux";
import thunkMiddleware from "redux-thunk";
import { configReducer } from "./configuration/reducers";
import { formReducer, FormState, pushErrorState } from "./form";
import { sessionReducer, SessionState } from "./session";
import { ConfigurationState } from "./configuration/types";
import { kycReducer, KycReduxState } from "./kyc";
import combineReducers from "combine-reducers-global-state";
import { SEVERITY } from "types";
import { singpassReducer, SingpassState } from "./singpass";

export * from "./session";
export * from "./form";
export * from "./kyc";
export * from "./singpass";

const rootReducer = combineReducers({
  form: formReducer,
  config: configReducer,
  session: sessionReducer,
  kyc: kycReducer,
  singpass: singpassReducer,
});

export interface AppState {
  form: FormState;
  config: ConfigurationState;
  session: SessionState;
  kyc: KycReduxState;
  singpass: SingpassState;
}

// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const catchAndPrintReduxErrors = (store: MiddlewareAPI) => {
  return (next: (action: any) => any) => {
    return (action: any) => {
      try {
        return next(action);
      } catch (error: any) {
        return error;
      }
    }
  };
};

export const store = createStore(
  rootReducer as Reducer<AppState>,
  composeEnhancers(applyMiddleware(catchAndPrintReduxErrors, thunkMiddleware))
);

export default rootReducer;
