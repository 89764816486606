import { ApiSession } from "../../types";

export enum SESSION_ACTIONS {
  REQUEST_NEW_SESSION_DETAILS = "REQUEST_NEW_SESSION_DETAILS",
  REQUEST_UPDATED_SESSION_DETAILS = "REQUEST_UPDATED_SESSION_DETAILS",
  RECEIVE_SESSION_DETAILS = "RECEIVE_SESSION_DETAILS",
}

export interface RequestNewSessionDetails {
  type: typeof SESSION_ACTIONS.REQUEST_NEW_SESSION_DETAILS;
}

export interface RequestUpdatedSessionDetails {
  type: typeof SESSION_ACTIONS.REQUEST_UPDATED_SESSION_DETAILS;
}
export interface ReceiveSessionDetails {
  type: typeof SESSION_ACTIONS.RECEIVE_SESSION_DETAILS;
  payload: ApiSession;
}

export type SessionActionTypes =
  | RequestNewSessionDetails
  | RequestUpdatedSessionDetails
  | ReceiveSessionDetails;

export interface SessionState {
  current: ApiSession | null;
  fetchingNew: boolean;
  fetchingUpdate: boolean;
}
