import {
  TEXT,
  SUMSUB_FLOW_NAMES,
  KYC_DOCUMENT_STATUS,
  KYC_ELEMENT,
  KYC_IDENTITY_STATUS,
  FORM_STEP,
} from "const";
import React, { useState } from "react";
import { CsButton } from "components/cs-button";
import { api, t, uiEvent, UI_EVENT, inIframe } from "utils";
import { SelfieIcon, QRScanIcon, PoaIcon, FtxLogoIcon } from "Icons";
import classnames from "classnames";
import "./style.scss";
import { SingpassSignIn } from "components/singpass-signin";
import {
  faCheck,
  faCross,
  faMobile,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppState, KycState } from "store";
import { API_BASE_URL, SELF_BASE_URL } from "config";
import { useSelector } from "react-redux";

export interface KycIntroViewProps {
  onNext: (action: string) => void;
  onSkip: () => void;
  skippable: boolean;
  sessionId: string;
  kycState: KycState;
}

// TODO: update lokalise for these text later when we enable this feature
// const KycIntroSelfie = () => {
//   return (
//     <div className="infoBlock">
//       <span className="imageBlock">
//         <SelfieIcon />
//       </span>
//       <span className="textBlock">
//         <span className="textTitle">{t(TEXT.QEX_SELFIE_TITLE)}</span>
//         <span className="textDescription">{t(TEXT.QEX_SELFIE_DESC)}</span>
//       </span>
//     </div>
//   );
// };

// const KycIntroIndentification = () => {
//   return (
//     <div className="infoBlock">
//       <span className="imageBlock">
//         <QRScanIcon />
//       </span>
//       <span className="textBlock">
//         <span className="textTitle">{t(TEXT.QEX_DOC_TITLE)}</span>
//         <span className="textDescription">{t(TEXT.QEX_DOC_DESC)}</span>
//       </span>
//     </div>
//   );
// };

// const KycIntroProofOfAddressSkippable = ({
//   onSkip,
// }: {
//   onSkip: () => void;
// }) => {
//   return (
//     <div className="infoBlock poaBlock">
//       <span className="imageBlock">
//         <PoaIcon />
//       </span>
//       <span className="textBlock">
//         <span className="textTitle">{t(TEXT.QEX_POA_TITLE)}</span>
//         <span className="textDescription">
//           <p style={{ marginBottom: "0.5rem" }}>
//             {t(TEXT.QEX_POA_PROOF_DESC, undefined, { currency: "USD" })}
//           </p>
//           <p>{t(TEXT.QEX_POA_DESC)}</p>
//         </span>
//       </span>
//       <CsButton onClick={onSkip}>{t(TEXT.QEX_POA_SKIP_FOR_NOW)}</CsButton>
//     </div>
//   );
// };

// const KycIntroProofOfAddress = () => {
//   return (
//     <div className="infoBlock poaBlock">
//       <span className="imageBlock">
//         <PoaIcon />
//       </span>
//       <span className="textBlock">
//         <span className="textTitle">{t(TEXT.QEX_POA_TITLE_INELIGIBLE)}</span>
//         <span className="textDescription">{t(TEXT.QEX_POA_DESC)}</span>
//       </span>
//     </div>
//   );
// };

// const RenderSumsubFlow = (kycState: KycState) => {
//   return (
//     <div>
//       {kycState.documents[KYC_ELEMENT.LIVENESS].status !==
//       KYC_DOCUMENT_STATUS.APPROVED ? (
//         <KycIntroSelfie />
//       ) : null}

//       {kycState.documents[KYC_ELEMENT.POI].status !==
//       KYC_DOCUMENT_STATUS.APPROVED ? (
//         <KycIntroIndentification />
//       ) : null}

//       {kycState.documents[KYC_ELEMENT.POA].status !==
//       KYC_DOCUMENT_STATUS.APPROVED ? (
//         <KycIntroProofOfAddress />
//       ) : null}
//     </div>
//   );
// };

export const KycIntroViewInner = (props: KycIntroViewProps) => {
  const [showQr, setShowQr] = useState(false);
  const sessionId = useSelector(
    (appState: AppState) => appState.session.current?.session_id
  );
  const helpUrl = useSelector(
    (appState: AppState) => appState.session.current?.help_url || 'https://help.liquid.com'
  );
  const singpassCache = useSelector(
    (appState: AppState) => appState.singpass.current
  );

  const kycData = useSelector((appState: AppState) => appState.kyc.current);

  return (
    <div className="kyc-intro">
      <div>
        <div className="kyc-block-container">
          {/* <span
            className={`kyc-status ${props.kycState.documents[
              KYC_ELEMENT.LIVENESS
            ].status.toLocaleLowerCase()}`}
          >
            {props.kycState.documents[KYC_ELEMENT.LIVENESS].status ===
            KYC_DOCUMENT_STATUS.APPROVED ? (
              <FontAwesomeIcon icon={faCheck} />
            ) : (
              <FontAwesomeIcon icon={faTimes} />
            )}
            Liveness
          </span>
          <span
            className={`kyc-status ${props.kycState.documents[
              KYC_ELEMENT.POI
            ].status.toLocaleLowerCase()}`}
          >
            {props.kycState.documents[KYC_ELEMENT.POI].status ===
            KYC_DOCUMENT_STATUS.APPROVED ? (
              <FontAwesomeIcon icon={faCheck} />
            ) : (
              <FontAwesomeIcon icon={faTimes} />
            )}
            Identification
          </span>
          <span
            className={`kyc-status ${props.kycState.documents[
              KYC_ELEMENT.POA
            ].status.toLocaleLowerCase()}`}
          >
            {props.kycState.documents[KYC_ELEMENT.POA].status ===
            KYC_DOCUMENT_STATUS.APPROVED ? (
              <FontAwesomeIcon icon={faCheck} />
            ) : (
              <FontAwesomeIcon icon={faTimes} />
            )}
            Address
          </span>
          <div className="sumsub-option">
            {RenderSumsubFlow(props.kycState)}
            <span
              className="next-btn button"
              onClick={() => {
                props.onNext("sumsub");
              }}
            >
              VERIFY WITH DOCUMENTS
            </span>

            <CsButton
              theme="light"
              onClick={() => {
                props.onNext("handoff");
              }}
            >
              {t(TEXT.QEX_COMPLETE_ON_MOBILE)}{" "}
              <FontAwesomeIcon icon={faMobile} />
            </CsButton>
          </div>
          <p style={{ textAlign: "center", marginTop: 15 }}>
            -&nbsp;&nbsp;OR&nbsp;&nbsp;-
          </p> */}
          <h2 className="kycTitleDescription text-bold">
            {t(TEXT.KYC_QUOTE_TITLE)}
          </h2>
          <p className="kycTextDescription">{t(TEXT.KYC_QUOTE_DESC)}</p>
          <div className="sumsub-option">
            <div className="infoBlock">
              <span className="imageBlock">
                <QRScanIcon />
              </span>
              <span className="textBlock">
                <span className="textTitle">
                  {t(TEXT.KYC_QUOTE_SINGPASS_SCAN)}
                </span>
                <span className="textDescription">
                  {t(TEXT.KYC_QUOTE_SINGPASS_DESC)}
                </span>
              </span>
            </div>
            <SingpassSignIn
              onClick={() =>
                api
                  .get(FORM_STEP.KYC_VIEW, `/api/v1/kyc/singpass_form`, {
                    caller_return_url: `${SELF_BASE_URL}/?s=${sessionId}`,
                  })
                  .then((response: any) => {
                    if (response.success) {
                      const SINGPASS_URL = response.payload.app_challenge_href;

                      const uiAction = "login_with_singpass";
                      uiEvent(UI_EVENT.BUTTON_CLICKED, uiAction, uiAction);
                      console.log(SINGPASS_URL);
                      if (inIframe()) {
                        window.open(SINGPASS_URL, "_blank");
                      } else {
                        window.open(SINGPASS_URL, "_self");
                      }
                    }
                  })
              }
            />
          </div>
          {(kycData?.documents[KYC_ELEMENT.POA].status ===
            KYC_DOCUMENT_STATUS.REJECTED ||
            kycData?.documents[KYC_ELEMENT.POI].status ===
              KYC_DOCUMENT_STATUS.REJECTED) && (
            <div
              className="singpass-invalid"
              dangerouslySetInnerHTML={{
                __html: t(TEXT.KYC_QUOTE_SINGPASS_PREVIOUS_ERROR, undefined, {
                  helpUrl,
                }),
              }}
            ></div>
          )}
          <footer>
            <div className="footer-partnership">
              <div className="footer-partnership-icon">
                <FtxLogoIcon />
              </div>
              <span>{t(TEXT.KYC_FOOTER_DESC)}</span>
            </div>
          </footer>
        </div>
      </div>
    </div>
  );
};
