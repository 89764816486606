import Joi from "@hapi/joi";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { API_BASE_URL } from "config";
import { Store } from "redux";
import { UserSessionToken } from "store/configuration/types";
import { FORM_STEP } from "../const";
import { pushErrorState } from "../store";
import {
  ApiResponse,
  ApiResponseSuccess,
  DisplayError,
  SEVERITY,
} from "../types";
import { createJwt } from "./session";

const digestError = (error: any) => {
  if (
    error !== undefined &&
    error.config !== undefined &&
    error.config.url !== undefined
  ) {
    return {
      request: {
        url: error.config.url,
        method: error.config.method,
        data: error.config.data,
        headers: error.config.headers,
      },
      response: {
        status: error.response ? error.response.status : undefined,
        headers: error.response ? error.response.headers : undefined,
        data: error.response ? error.response.data : undefined,
      },
    };
  }
};

const getMessage = (data: any): string => {
  if (data.message !== undefined) {
    let message = data.message;
    if (data.error !== undefined) {
      message += `\n` + data.error.code;
    } else {
      if (data.reason !== undefined) {
        message += `\n` + data.reason.message;
      }
    }
    return message;
  }
  return "Something went wrong";
};

interface HttpRequest {
  path: string;
  method: "get" | "post" | "put" | "delete";
  params?: any;
  data?: any;
  authRequired?: boolean;
}

interface HttpRequestQueue {
  request: HttpRequest;
  resolve: (
    value: AxiosResponse<any> | PromiseLike<AxiosResponse<any>>
  ) => void;
  reject: () => void;
}

export class QexApi {
  store?: Store<any>;
  token?: UserSessionToken;
  sessionSecret?: string;

  nonceOffset: number | null = null;

  queue: Array<HttpRequestQueue> = [];

  syncTime = () => {
    axios({
      method: "get",
      url: `${API_BASE_URL}/api/v1/time`,
    }).then((response) => {
      const { unix_ms } = response.data;
      const now = Date.now();
      const delta = now - unix_ms;
      //console.log({ now, unix_ms, delta });
      this.nonceOffset = delta;
      this.processHttpQueue();
    });
  };

  setStore(store: Store<any>) {
    this.store = store;
  }

  setSessionSecret(token: UserSessionToken) {
    this.token = token;
    this.processHttpQueue();
  }

  hasSessionSecret() {
    return this.token !== undefined && this.token.session_secret !== undefined;
  }

  createHeaders(path: string, nonceOffset: number) {
    if (this.token === undefined || this.token.session_secret === undefined) {
      return undefined;
    }
    return {
      authorization: createJwt(this.token, path, Date.now() - nonceOffset),
    };
  }

  processHttpResponse<SuccessResponseType>(
    response:
      | AxiosResponse<ApiResponse<SuccessResponseType>>
      | undefined
      | null,
    context: FORM_STEP | null,
    surpressErrors?: boolean
  ): ApiResponseSuccess<SuccessResponseType> | DisplayError {
    if (response !== undefined && response !== null) {
      if (response.status >= 500 && response.status < 600) {
        const e: DisplayError = {
          severity: SEVERITY.FATAL,
          code: "server_error",
          message: getMessage(response.data),
          context: null,
        };
        if (this.store !== undefined) {
          this.store.dispatch(pushErrorState(e));
        }
        return e;
      }

      if (response.data !== undefined) {
        if (response.data.success === true) {
          return response.data;
        } else if (response.data?.error?.reason !== undefined) {
          let firstError: DisplayError | undefined;
          const e: DisplayError = {
            severity: SEVERITY.INVALID,
            code: response.data.error.reason.code,
            message: response.data.error.reason.message,
            context: context,
          };
          if (firstError === undefined) {
            firstError = e;
          }
          if (surpressErrors !== true) {
            if (this.store !== undefined) {
              this.store.dispatch(pushErrorState(e));
            }
          }
          if (firstError !== undefined) {
            return firstError;
          }
        }
      }
    }
    const e: DisplayError = {
      severity: SEVERITY.INVALID,
      code: "general_http_failure",
      message: "Something went wrong",
      context: null,
    };
    if (this.store !== undefined) {
      this.store.dispatch(pushErrorState(e));
    }
    return e;
  }
  readyToSend(authRequired?: boolean) {
    if (this.nonceOffset === null) {
      return false;
    }
    if (authRequired && this.token?.session_secret === undefined) {
      return false;
    }
    return true;
  }
  http(request: HttpRequest) {
    if (!this.readyToSend(request.authRequired)) {
      return new Promise<AxiosResponse>((resolve, reject) => {
        // add this to the queue to call when the information is available
        this.queue.push({
          request,
          resolve,
          reject,
        });
      });
    } else {
      return this.httpNow(request);
    }
  }
  httpNow(request: HttpRequest) {
    if (this.nonceOffset === null) {
      throw new Error(
        "nonceOffset not set. Don't call httpNow directly, instead call http()"
      );
    }
    if (request.authRequired && this.token?.session_secret === null) {
      throw new Error(
        "sessionSecret not set. Don't call httpNow directly, instead call http()"
      );
    }
    return axios({
      method: request.method,
      url: `${API_BASE_URL}${request.path}`,
      params: request.params,
      headers: this.createHeaders(request.path, this.nonceOffset),
      data: request.data,
    });
  }
  processHttpQueue() {
    const notProcessed: Array<HttpRequestQueue> = [];
    for (const q of this.queue) {
      if (this.readyToSend(q.request.authRequired)) {
        this.httpNow(q.request).then(q.resolve).catch(q.reject);
      } else {
        notProcessed.push(q);
      }
    }
    this.queue = notProcessed;
  }

  get<SuccessResponseType>(
    context: FORM_STEP | null,
    path: string,
    params?: any,
    validation?: Joi.Schema,
    surpressErrors?: boolean,
    authRequired?: boolean,
  ) {
    return this.http({ method: "get", path, params, authRequired }).then(
      (response) =>
        this.processHttpResponse<SuccessResponseType>(
          response,
          context,
          surpressErrors
        ),
      (error) => {
        api.pushLog("HTTP_GET_ERROR", "", context, digestError(error));
        return this.processHttpResponse<SuccessResponseType>(
          error.response,
          context,
          surpressErrors
        );
      }
    );
  }

  post<SuccessResponseType>(
    context: FORM_STEP | null,
    path: string,
    data: any,
    validation: Joi.Schema,
    surpressErrors?: boolean,
    authRequired?: boolean,
  ) {
    return this.http({ method: "post", path, data, authRequired }).then(
      (response) =>
        this.processHttpResponse<SuccessResponseType>(
          response,
          context,
          surpressErrors
        ),
      (error) => {
        api.pushLog("HTTP_POST_ERROR", "", context, digestError(error));
        return this.processHttpResponse<SuccessResponseType>(
          error.response,
          context,
          surpressErrors
        );
      }
    );
  }

  put<SuccessResponseType>(
    context: FORM_STEP | null,
    path: string,
    data: any,
    validation: Joi.Schema,
    surpressErrors?: boolean,
    authRequired?: boolean,
  ) {
    return this.http({
      method: "put",
      path,
      data: data,
      authRequired,
    }).then(
      (response) =>
        this.processHttpResponse<SuccessResponseType>(
          response,
          context,
          surpressErrors
        ),
      (error) => {
        api.pushLog("HTTP_POST_ERROR", "", context, digestError(error));
        return this.processHttpResponse<SuccessResponseType>(
          error.response,
          context,
          surpressErrors
        );
      }
    );
  }

  delete<SuccessResponseType>(
    context: FORM_STEP | null,
    path: string,
    params?: any,
    validation?: Joi.Schema,
    surpressErrors?: boolean,
    authRequired?: boolean,
  ) {
    return this.http({
      method: "delete",
      path,
      params: params,
      authRequired,
    }).then(
      (response) =>
        this.processHttpResponse<SuccessResponseType>(
          response,
          context,
          surpressErrors
        ),
      (error) => {
        api.pushLog("HTTP_DELETE_ERROR", "", context, digestError(error));
        return this.processHttpResponse<SuccessResponseType>(
          error.response,
          context,
          surpressErrors
        );
      }
    );
  }

  transactionId: string | undefined;
  setTransactionId(transactionId: string) {
    this.transactionId = transactionId;
  }
  pushLog(
    event: string,
    trigger?: string,
    context?: FORM_STEP | null,
    metadata?: any
  ) {
    // const transactionId = this.transactionId;
    // if (transactionId === undefined) {
    //   return;
    // }
    // const path = `/api/v1/transaction/${transactionId}/log`;
    // return axios({
    //   method: "post",
    //   url: `${API_BASE_URL}${path}`,
    //   data: {
    //     event,
    //     trigger,
    //     context: context !== null ? context : undefined,
    //     metadata,
    //   },
    //   headers: this.createHeaders(path),
    // }).then(
    //   () => undefined,
    //   () => undefined
    // );
  }
}

export const api = new QexApi();
api.syncTime();
setInterval(api.syncTime, 60 * 60 * 1000);
