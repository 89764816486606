import { FORM_STEP, SPECIAL_VIEW } from "const";
import { api, getBackStep, getNextStep } from "../../utils";
import {
  Dispatch,
  DisplayError,
  GetState,
  StepChange,
  MODAL_VIEW,
} from "../../types";
import {
  ClearErrorState,
  PushErrorState,
  SetShowModal,
  SetFormStep,
  DismissModal,
  FORM_ACTIONS,
} from "./types";
import { SetSpecialView } from "store";

/**
 * Sets the step view of the form.
 * It's _very_ unlikely you need to call this directly.
 *
 * @param step
 */
export const setFormStep = (step: StepChange): SetFormStep => {
  return {
    type: FORM_ACTIONS.SET_FORM_STEP,
    payload: {
      newStep: step.step,
    },
  };
};

export const resetForm = () => {
  return (dispatch: Dispatch, getState: GetState) => {
    dispatch({
      type: FORM_ACTIONS.RESET_FORM,
    });
  };
};

export const formNext = (
  trigger: string,
  triggerMeta?: any,
  preferredStep?: FORM_STEP
) => (dispatch: Dispatch, getState: GetState) => {
  const appState = getState();
  const s = getNextStep(appState, trigger, triggerMeta, preferredStep);
  if (appState.form.formStep !== s.step) {
    switch (s.step) {
      case FORM_STEP.FAILURE_VIEW:
        api.pushLog(
          "FORM_STEP_ERROR",
          s.trigger,
          appState.form.formStep,
          appState.form.errorState
        );
        break;
      default:
        api.pushLog("STEP_TRANSITION", s.trigger, s.step, {
          old: appState.form.formStep,
          new: s.step,
        });
        break;
    }
    dispatch(setFormStep(s));
  }
};

export const formBack = (trigger: string, triggerMeta?: any) => {
  return (dispatch: Dispatch, getState: GetState) => {
    const appState = getState();
    const s = getBackStep(trigger, appState.form.formStep, triggerMeta);
    if (s !== null) {
      dispatch(
        setFormStep(getNextStep(appState, trigger, triggerMeta, s.step))
      );
    }
  };
};

export const pushErrorState = (error: DisplayError): PushErrorState => {
  api.pushLog("PUSH_ERROR_STATE", error.trigger, error.context, error);
  return {
    type: FORM_ACTIONS.PUSH_ERROR_STATE,
    payload: error,
  };
};

export const clearErrorState = (filter?: {
  code?: string;
  context?: FORM_STEP | null;
}): ClearErrorState => {
  return {
    type: FORM_ACTIONS.CLEAR_ERROR_STATE,
    payload: filter,
  };
};

export const setShowModal = (data: MODAL_VIEW): SetShowModal => {
  return {
    type: FORM_ACTIONS.SET_SHOW_MODAL,
    payload: data,
  };
};

export const dismissModal = (): DismissModal => {
  return {
    type: FORM_ACTIONS.DISMISS_MODAL,
  };
};

export const setSpecialView = (view: SPECIAL_VIEW | null): SetSpecialView => {
  return {
    type: FORM_ACTIONS.SET_SPECIAL_VIEW,
    payload: view,
  };
};
