import Joi from "@hapi/joi";
export const SESSION_RESPONSE = Joi.any();

// TODO Define response validation
export const TRANSACTION_RESPONSE = Joi.any();

// TODO Define response validation
export const SETTLEMENT_RESPONSE = Joi.any();

// TODO Define response validation
export const QUOTE_IN_SESSION_RESPONSE = Joi.any();
