import { FORM_STEP, LOCALSTORAGE_SESSION_ID_SUFFIX } from "../../const";
import { storeSessionId, api } from "../../utils";
import {
  ApiResponse,
  ApiSession,
  Dispatch,
  DisplayError,
  GetState,
  MODAL_VIEW,
} from "../../types";
import {
  ReceiveSessionDetails,
  RequestNewSessionDetails,
  RequestUpdatedSessionDetails,
  SESSION_ACTIONS,
} from "./types";

import { SESSION_RESPONSE } from "./validation";
import { history } from "../../utils/history";
import { formNext, pushErrorState, setShowModal } from "../form/actions";
import { FORM_TRIGGER_ACTIONS } from "../form/types";
import { cacheHandoffResponse, fetchHandoffCache } from "../../utils/session";

import { fetchKycState } from "../kyc";

export const requestNewSessionDetails = (): RequestNewSessionDetails => {
  return {
    type: SESSION_ACTIONS.REQUEST_NEW_SESSION_DETAILS,
  };
};

export const requestUpdatedSessionDetails = (): RequestUpdatedSessionDetails => {
  return {
    type: SESSION_ACTIONS.REQUEST_UPDATED_SESSION_DETAILS,
  };
};

export const receiveSessionDetails = (
  data: ApiSession
): ReceiveSessionDetails => {
  history.push(`/?s=${data.session_id}`);
  return {
    type: SESSION_ACTIONS.RECEIVE_SESSION_DETAILS,
    payload: data,
  };
};

// export const createSessionDetails = (
//   context: FORM_STEP | null = null,
//   identity?: ConfigurationIdentity
// ) => {
//   return async (
//     dispatch: Dispatch,
//     getState: GetState
//   ): Promise<ApiResponse<ApiSession> | DisplayError> => {
//     const state = getState();
//     const apiKey = state.config.publicApiKey;

//     const urlParams = new URLSearchParams(window.location.search);

//     let ancestorOrigins: Array<string> = [];
//     try {
//       for (let i = 0; i < window.location.ancestorOrigins.length; i++) {
//         ancestorOrigins.push(window.location.ancestorOrigins[i]);
//       }
//     } catch {}

//     dispatch(requestNewSessionDetails());
//     return api
//       .post<ApiSession>(
//         context,
//         `/api/v1/session`,
//         {
//           public_api_key: apiKey,
//           ancestor_origins: ancestorOrigins,
//           identity: identity || state.config.identity,
//           user_locale: state.config.user_locale,
//           application_config: {
//             ...state.config,
//             initialization_payload_raw: null,
//           },
//           force_kyc: urlParams.has("force-kyc"),
//           initialization_payload_raw: state.config.initialization_payload_raw,
//         },

//         SESSION_RESPONSE
//       )
//       .then((result) => {
//         if (result.success) {
//           dispatch(receiveSessionDetails(result.payload));
//           api.setSessionSecret(result.payload);
//           dispatch(formNext("CREATE_SESSION"));
//           dispatch(fetchKycState(context));
//           storeSessionId(
//             result.payload.public_api_key,
//             result.payload.session_id,
//             result.payload.session_secret
//           );
//         } else {
//           dispatch(pushErrorState(result as DisplayError));
//         }
//         return result;
//       });
//   };
// };

export const fetchSessionFromHandover = (
  context: FORM_STEP | null = null,
  handoverId: string
) => {
  return async (
    dispatch: Dispatch,
    getState: GetState
  ): Promise<any> => {
    const cachedHandoff = fetchHandoffCache(handoverId) as any;
    if (cachedHandoff) {
      return new Promise((resolve, reject) => {
        api.setSessionSecret(cachedHandoff.payload);
        dispatch(receiveSessionDetails(cachedHandoff.payload));
        dispatch(
          formNext(FORM_TRIGGER_ACTIONS.HANDOFF_SUCCESS, cachedHandoff.payload, cachedHandoff.payload.preferred_step)
        );
        dispatch(fetchKycState(context));
        storeSessionId(
          LOCALSTORAGE_SESSION_ID_SUFFIX,
          cachedHandoff.payload.session_id,
          cachedHandoff.payload.session_secret
        );
        resolve(cachedHandoff);
      });

    }
    dispatch(requestNewSessionDetails());
    return api
      .get<ApiSession>(
        context,
        `/api/v1/session`,
        {
          handoff_id: handoverId,
        },
        SESSION_RESPONSE
      )
      .then((result) => {
        if (result.success) {
          api.setSessionSecret(result.payload);
          dispatch(receiveSessionDetails(result.payload));
          dispatch(
            formNext(FORM_TRIGGER_ACTIONS.HANDOFF_SUCCESS, result.payload, result.payload.preferred_step)
          );
          dispatch(fetchKycState(context));
          storeSessionId(
            "todo",
            result.payload.session_id,
            result.payload.session_secret
          );

          cacheHandoffResponse(handoverId, result);
        } else {
          console.error(FORM_TRIGGER_ACTIONS.HANDOFF_FAILED, result);
          dispatch(pushErrorState(result as DisplayError));
          dispatch(formNext(FORM_TRIGGER_ACTIONS.HANDOFF_FAILED, null, FORM_STEP.FAILURE_VIEW));
        }
        return result;
      });
  };
};

export const fetchSessionFromCorrelationKey = (
  context: FORM_STEP | null = null,
  public_api_key: string,
  correlation_key: string,
  objective: string
) => {
  return async (
    dispatch: Dispatch,
    getState: GetState
  ): Promise<ApiResponse<ApiSession> | DisplayError> => {
    dispatch(requestNewSessionDetails());
    return api
      .get<ApiSession>(
        context,
        `/api/v1/session`,
        {
          public_api_key,
          correlation_key,
          objective,
        },
        SESSION_RESPONSE
      )
      .then((result) => {
        if (result.success) {
          api.setSessionSecret(result.payload);
          dispatch(receiveSessionDetails(result.payload));
          dispatch(
            formNext(
              "CORRELATION",
              result.payload,
              result.payload.preferred_step
            )
          );
          dispatch(fetchKycState(context));
          storeSessionId(
            "todo",
            result.payload.session_id,
            result.payload.session_secret
          );
        } else {
          console.error("CORRELATION FAILED", result);
          dispatch(pushErrorState(result as DisplayError));
        }
        return result;
      });
  };
};

let pollSessionTimer: number | undefined;
export const pollSession = (
  context: FORM_STEP,
  untilTrue: (prev: ApiSession | null, next: ApiSession) => boolean,
  pollDuration: number = 250
) => {
  if (pollSessionTimer !== undefined) {
    throw new Error("Cannot start polling session status a second time.");
  }
  return (
    dispatch: (a: any) => Promise<ApiResponse<ApiSession>>,
    getState: GetState
  ) => {
    return new Promise((resolve, reject) => {
      const state = getState();
      // run initial fetch to avoid epmty screen
      dispatch(fetchUserSession(context));
      const sessionDetails = state.session.current;
      const sessionDetailsPrev = sessionDetails;
      const liquidUserIdBefore =
        sessionDetails !== null ? sessionDetails.liquid_user_id : null;
      let pollCount: number = 0;
      /**
       * This function might poll, might not.
       * Some exponential backoff logic
       */
      const gankaShankMaybePollMaybeDont = () => {
        ++pollCount;

        const powerOf2 = (v: number) => v && !(v & (v - 1));

        if (!powerOf2(pollCount) && pollCount % 16 !== 0) return; // do nothing

        pollNowForRealDontSkip();
      };

      const handleVisibilityChange = () => {
        // optimise polling
        if (document.visibilityState === "visible") {
          pollNowForRealDontSkip();
        }
      };

      /**
       * This function will poll every time you call it :thumbs_up:
       */
      const pollNowForRealDontSkip = () => {
        dispatch(fetchUserSession(context)).then((response) => {
          if (response.success) {
            const userSession = response.payload;
            const liquidUserIdNow = userSession.liquid_user_id;
            if (
              liquidUserIdBefore !== liquidUserIdNow &&
              liquidUserIdNow !== undefined &&
              liquidUserIdNow !== null
            ) {
              dispatch(setShowModal(MODAL_VIEW.SIGNIN_SUCCESS));
            }
            if (untilTrue(sessionDetailsPrev, userSession)) {
              // don't need to update form step, this is handled by src/utils/form.ts
              dispatch(stopPollSession());
              document.removeEventListener(
                "visibilitychange",
                handleVisibilityChange
              );
              resolve(getState());
            }
          }
        });
      };

      document.addEventListener("visibilitychange", handleVisibilityChange);

      pollSessionTimer = setInterval(
        gankaShankMaybePollMaybeDont,
        pollDuration
      ) as any;
    });
  };
};

export const stopPollSession = () => {
  return () => {
    if (pollSessionTimer !== undefined) {
      clearInterval(pollSessionTimer);
      pollSessionTimer = undefined;
    }
  };
};

let sessionPollErrors = 0;
export const fetchUserSession = (
  context: FORM_STEP | null = null,
  sessionId?: string
) => {
  return (
    dispatch: Dispatch,
    getState: GetState
  ): Promise<ApiResponse<ApiSession> | DisplayError> => {
    if (sessionId === undefined) {
      sessionId = getState().session.current?.session_id;
    }
    dispatch(requestUpdatedSessionDetails());
    return api
      .get<ApiSession>(
        context,
        `/api/v1/session/${sessionId}`,
        {},
        SESSION_RESPONSE
      )
      .then((result) => {
        if (result.success) {
          sessionPollErrors = 0;
          dispatch(receiveSessionDetails(result.payload));
          dispatch(fetchKycState(context));
          dispatch(formNext(FORM_TRIGGER_ACTIONS.FETCH_USER_SESSION));
          // dispatch(fetchUserAccounts(context))
        } else {
          sessionPollErrors += 1;
          if (sessionPollErrors >= 5) {
            dispatch(pushErrorState(result as DisplayError));
          }
        }
        return result;
      });
  };
};

export const fetchUserAccounts = (context: FORM_STEP | null = null) => {
  return (dispatch: Dispatch, getState: GetState): Promise<any> => {
    const sessionId = getState().session.current?.session_id;
    if (sessionId === undefined) {
      return Promise.resolve();
    }

    return api
      .get<ApiSession>(
        context,
        `/api/v1/session/${sessionId}/accounts`,
        {},
        SESSION_RESPONSE,
        true,
        true,
      )
      .then((result) => {
        return result;
      });
  };
};
