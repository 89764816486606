interface Interfacelanguage {
  [key: string]: string;
}

export const normalizeLanguage = (language: string = ""): string => {
  const languageMapping: Interfacelanguage = {
    es: "es",
    ja: "ja",
    jp: "ja",
    ko: "en",
    zhcn: "zhcn",
    "zh-hk": "zhtw",
    yue: "zhtw",
    zhtw: "zhtw",
    "zh-tw": "zhtw",
    "zh-hant": "zhtw",
    "cmn-hant": "zhtw",
    cmn: "zhcn",
    zh: "zhcn",
    ru: "ru",
    vn: "vi",
    vi: "vi",
    en: "en",
  };

  let locale = language.toLowerCase();
  do {
    const mapped = languageMapping[locale];
    if (mapped !== undefined) {
      return mapped;
    }

    let temp = locale.split("-"); //['yue','hant','hk]

    locale = temp.slice(0, -1).join("-");
  } while (locale !== "");
  return "en";
};
export const getBrowserLocale = () => {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has("userLocale") && urlParams.get("userLocale")) {
    const userLocale = urlParams.get("userLocale") || "";
    return normalizeLanguage(userLocale);
  }
  const w = window as any;
  if (w === undefined || w === null) return null;
  const n = w.navigator;
  if (n === undefined || n === null) return null;
  if (n.languages !== undefined && n.languages.length > 0) {
    return n.languages[0];
  }
  if (n.userLanguage) return n.userLanguage;
  if (n.language) return n.language;
  if (n.browserLanguage) return n.browserLanguage;
};
