import { FORM_STEP, SETTLEMENT_DIRECTION } from "../../const";
import { SEVERITY } from "types";
import { history } from "utils/history";
import { getFormPercent, stepTransition } from "../../utils";
import { FormActionTypes, FormState, FORM_ACTIONS } from "./types";
import { AppState } from "store";

const initialState: FormState = {
  errorState: [],
  formStep: FORM_STEP.LOADING_VIEW,
  showModal: null,
  specialView: null,
};

export const formReducer = (
  state = initialState,
  action: FormActionTypes,
  appState: AppState
): FormState => {
  switch (action.type) {
    case FORM_ACTIONS.SET_FORM_STEP:
      if (action.payload.newStep !== state.formStep) {
        switch (action.payload.newStep) {
          case FORM_STEP.FAILURE_VIEW:
            stepTransition(
              action.payload.newStep,
              state.formStep,
              getFormPercent(action.payload.newStep),
              state.errorState
            );
            break;
          default:
            stepTransition(
              action.payload.newStep,
              state.formStep,
              getFormPercent(action.payload.newStep),
              null
            );
            break;
        }
        return {
          ...state,
          formStep: action.payload.newStep,
        };
      }
      return state;
    case FORM_ACTIONS.RESET_FORM:
      return initialState;
    case FORM_ACTIONS.PUSH_ERROR_STATE:
      if (action.payload.severity === SEVERITY.FATAL) {
        const view =
          action.payload.code === "issuer_country_rejected"
            ? FORM_STEP.COMING_SOON_VIEW
            : FORM_STEP.FAILURE_VIEW;
        stepTransition(view, state.formStep, 0, [action.payload]);
        return {
          ...state,
          errorState: [...state.errorState, action.payload],
          formStep: view,
        };
      } else {
        return {
          ...state,
          errorState: [...state.errorState, action.payload],
        };
      }
    case FORM_ACTIONS.CLEAR_ERROR_STATE:
      const { payload } = action;
      if (payload === undefined) {
        return {
          ...state,
          errorState: [],
        };
      } else {
        return {
          ...state,
          errorState: state.errorState.filter(
            (e) =>
              (payload.code !== undefined && e.code !== payload.code) ||
              (payload.context !== undefined && e.context !== payload.context)
          ),
        };
      }

    case FORM_ACTIONS.SET_SHOW_MODAL:
      return {
        ...state,
        showModal: action.payload,
      };
    case FORM_ACTIONS.DISMISS_MODAL:
      return {
        ...state,
        showModal: null,
      };
    case FORM_ACTIONS.SET_SPECIAL_VIEW:
      return {
        ...state,
        specialView: action.payload,
      };
    default:
      return state;
  }
};
