import * as React from "react";
// import QrCreator from "qr-creator";
import { useState, useEffect } from "react";
import "./style.scss";

interface QRGeneratorProps {
  url: string;
}
export const QRGenerator = (props: QRGeneratorProps) => {
  const [divElem, setDivElem] = useState<HTMLDivElement | null>(null);
  const { url } = props;
  useEffect(() => {
    if (divElem !== null) {
      divElem.innerHTML = "";
      // QrCreator.render(
      //   {
      //     text: url,
      //     radius: 0.5, // 0.0 to 0.5
      //     ecLevel: "L", // L, M, Q, H
      //     fill: "#000000", // foreground color
      //     background: "#FFF", // color or null for transparent
      //     size: 180, // in pixels
      //   },
      //   divElem
      // );
    }
  }, [divElem, url]);
  return (
    <div className="qr-code">
      <div ref={setDivElem} className="qr-img">
        TODO: Find a new QR library
      </div>
    </div>
  );
};
