export interface CustomAttributesConfig {
  color?: string;
  background?: string;
  "border-color"?: string;
}

export interface CustomStyleAttributesCSS {
  color?: string;
  background?: string;
  border?: string;
}

export enum CUSTOM_STYLE {
  CsBase = "cs-base",
  CsButton = "cs-button",
  CsError = "cs-error",
  CsHighlight = "cs-highlight",
  CsInteractive = "cs-interactive",
  CsSelect = "cs-select",
  CsSpecial = "cs-special",
  CsSpecialSubtext = "cs-special-subtext", // intentionally undocumented
  CsSubtext = "cs-subtext",
  CsIcon = "cs-icon",
}

export const CUSTOM_STYLES = Object.values(CUSTOM_STYLE);

export type CustomStylesConfig = {
  [cs in CUSTOM_STYLE]?: CustomAttributesConfig;
};
