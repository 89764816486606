import Joi from "@hapi/joi";

export const INITIALIZATION_CONFIG = {
  "1.2": Joi.object({
    public_api_key: Joi.string().required(),
    config_version: Joi.string().required(),
    legacyFeatures: Joi.boolean(),
    theme: Joi.string(),
    user_locale: Joi.any(),
    allow_trade: Joi.boolean(),
    quote: Joi.any(),
    side: Joi.string(),
    funding_default: Joi.any(),
    payout_default: Joi.any(),
    funding_settlement: Joi.any(),
    payout_settlement: Joi.any(),
    render_views: Joi.any(),
    custom_styles: Joi.any(),
    terms_of_service: Joi.any(),
    verbose_logging: Joi.boolean(),
    identity: Joi.any(),
    surpress_sandbox_warning: Joi.boolean(),
    special_layout: Joi.string().valid("merchant", "horizontal"),
    intent_id: Joi.string(),
    partner_order_id: Joi.string(),
    partner_ref: Joi.string(),
    partner_tags: Joi.array().items(Joi.string()),
    affiliate_id: Joi.string(),
    affiliate_ref: Joi.string(),
    affiliate_tags: Joi.array().items(Joi.string()),
  }),
};
