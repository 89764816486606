import { useState } from "react";
import Joi from "@hapi/joi";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { Col, Form } from "react-bootstrap";
// import PhoneInput from 'react-phone-input-2'
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import classnames from "classnames";
import { resetSingpass } from "store";

import { t } from "utils/t";
import {
  FORM_STEP,
  KYC_ELEMENT,
  TEXT,
  SINGPASS_VALIDATION_STATUS,
} from "const";
import { AppState, putResponse } from "store";
import { api, calculateAge } from "utils";

import { Checkbox } from "components/checkbox";
import { CsButton } from "components/cs-button";
import { ErrorText } from "components/error-text";
import { fetchSingpassCache } from "store/singpass";
import SingpassLogo from "./singpass-logo.png";

import "./style.scss";

type RBRef = string & ((ref: Element | null) => void);
const getLastErrorFromFroms = (errors: any): string | null => {
  return errors !== undefined &&
    errors !== null &&
    Object.keys(errors).length > 0
    ? (Object.values(errors)[0] as any).message
    : null;
};
export const SingpassAgreementView = () => {
  const [sgChecked, setSgChecked] = useState(false);
  const [tosChecked, setTosChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const latestVersion = useSelector((state: AppState) => {
    if (
      state.kyc.current == undefined ||
      state.kyc.current.documents == undefined ||
      state.kyc.current.documents[KYC_ELEMENT.PDS] == undefined ||
      state.kyc.current.documents[KYC_ELEMENT.PDS].metadata === undefined
    ) {
      return "";
    }
    return state.kyc.current.documents[KYC_ELEMENT.PDS].metadata.latest_version;
  });
  const singpassCache = useSelector(
    (appState: AppState) => appState.singpass.current
  );

  if (!singpassCache) {
    return <div>No Singpass Data</div>;
  }

  const { register, handleSubmit, errors, control } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: {},
    validationResolver: undefined,
    validationContext: undefined,
    validateCriteriaMode: "firstError",
    submitFocusError: true,
  });

  const onNext = async (data: { phone_number: string; email: string }) => {
    try {
      const res = api.post(
        FORM_STEP.SINGPASS_AGREEMENT_VIEW,
        `/api/v1/singpass/cached/accept`,
        data,
        Joi.any()
      );
      dispatch(fetchSingpassCache() as any), setLoading(true);
    } catch {
      setLoading(false);
    }
  };

  let oneErrorOrNull = getLastErrorFromFroms(errors);
  const onSubmit = (raw: any, e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const params = {
      email: raw.email,
      phone_number: raw.phoneNumber,
    };
    console.log({ params });
    onNext(params);
  };

  const handleResetSingpass = () => {
    dispatch(resetSingpass(null) as any);
  };

  const userData = singpassCache?.user || [];
  const emailItem = userData.find((item) => item.label === "Email");

  const defaultMobile: string =
    userData.find((item) => item.label === "Mobile")?.value || "";
  const defaultNationality: string =
    userData.find((item) => item.label === "Nationality")?.value ||
    "";
  const defaultDateOfBirth: string =
    userData.find((item) => item.label === "Date of Birth")?.value ||
    "";
  const singpassCacheComment = singpassCache?.validation?.comment;
  const isEnableReset = !singpassCache?.validation?.passed;

  let singpassErrorMessage = "";

  switch (singpassCacheComment) {
    case SINGPASS_VALIDATION_STATUS.MISMATCHED_NAME:
      singpassErrorMessage = t(TEXT.KYC_ERROR_MISMATCHED_NAME);
      break;
    case SINGPASS_VALIDATION_STATUS.MISSING_ADDRESS:
      singpassErrorMessage = t(TEXT.KYC_ERROR_MISSING_ADDRESS);
      break;
    case SINGPASS_VALIDATION_STATUS.INVALID_ADDRESS:
      singpassErrorMessage = t(TEXT.KYC_ERROR_INVALID_ADDRESS);
      break;
    case SINGPASS_VALIDATION_STATUS.UNVERIFIED_ADDRESS:
      singpassErrorMessage = t(TEXT.KYC_ERROR_UNVERIFIED_ADDRESS);
      break;
    case SINGPASS_VALIDATION_STATUS.INVALID_AGE:
      if (calculateAge(new Date(defaultDateOfBirth)) > 70) {
        singpassErrorMessage = t(TEXT.KYC_ERROR_INVALID_AGE_ABOVE, undefined,{
          age: `70`,
        })
      } else {
        singpassErrorMessage = t(TEXT.KYC_ERROR_INVALID_AGE_BELOW, undefined,{
          age: `18`,
        })
      }
      break;
    case SINGPASS_VALIDATION_STATUS.INVALID_NATIONALITY:
      singpassErrorMessage = t(TEXT.KYC_ERROR_INVALID_NATIONALITY, undefined,{
        defaultNationality: defaultNationality,
      });
      break;
    case SINGPASS_VALIDATION_STATUS.INTERNAL_ERROR:
      singpassErrorMessage = `Internal System Error`;
      break;
    default:
      singpassErrorMessage = "";
      break;
  }

  return (
    <div className="singpass-agreement">
      <div className="logo-wrapper">
        <img src={SingpassLogo} alt="singpass-logo" />
      </div>
      <p className="singpass-agreement-desc">
        {t(TEXT.KYC_SINGPASS_AGREEMENT_TITLE)}
      </p>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {userData.map((s) => {
          if (s.label === "Mobile" || s.label === "Email") return null;
          return (
            <div>
              <label className="fieldLabel">{s.label}</label>
              <span className="inputDisabled">{s.value}</span>
            </div>
          );
        })}
        {singpassErrorMessage && (
          <div className="singpass-invalid">
            <FontAwesomeIcon icon={faTriangleExclamation} color="red" />
            <span dangerouslySetInnerHTML={{ __html: singpassErrorMessage }}></span>
          </div>
        )}
        <Form.Group className="formGroup customInput">
          <label className="">{t(TEXT.KYC_PHONE)}</label>
          <Controller
            as={PhoneInput}
            control={control}
            smartCaret={false}
            placeholder={t(TEXT.KYC_PHONE_PLACEHOLDER)}
            className="phone-input fieldInput"
            rules={{
              required: t(TEXT.KYC_PHONE_INVALID),
              minLength: {
                value: 7,
                message: t(TEXT.KYC_PHONE_INVALID),
              },
            }}
            value={defaultMobile}
            defaultValue={defaultMobile}
            onChange={([phone]) => {
              return phone;
            }}
            name="phoneNumber"
          />
        </Form.Group>
        <Form.Group
          className={classnames("formGroup customInput", {
            "disabled-field": emailItem && emailItem.editable === false,
          })}
        >
          <label className="">
            {t(TEXT.KYC_EMAIL_PLACEHOLDER)}
            <span className="required-star">*</span>
          </label>
          <Form.Control
            className={"formInput fieldInput"}
            type="email"
            defaultValue={emailItem ? emailItem.value : ""}
            placeholder={t(TEXT.KYC_EMAIL_PLACEHOLDER)}
            disabled={emailItem && emailItem.editable === false}
            ref={
              register({
                required: t(TEXT.KYC_EMAIL_INVALID),
                pattern: {
                  value:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: t(TEXT.KYC_EMAIL_INVALID),
                },
              }) as RBRef
            }
            name="email"
          />
        </Form.Group>
        <Checkbox
          value={sgChecked}
          onChange={(v) => setSgChecked(v)}
          text={t(TEXT.KYC_AGREEMENT_ALLOW_PERMISSION)}
          id="singpass"
        />
        <Checkbox
          value={tosChecked}
          onChange={(v) => setTosChecked(v)}
          text={
            <span dangerouslySetInnerHTML={{ __html: t(TEXT.KYC_AGREEMENT_TERM_AND_CONDITIONS)}}>
            </span>
          }
          id="tos"
        />
        {oneErrorOrNull !== null ? (
          <ErrorText message={oneErrorOrNull} />
        ) : null}
        {isEnableReset ? (
          <CsButton loading={loading} onClick={handleResetSingpass}>
            {t(TEXT.KYC_TRY_AGAIN)}
          </CsButton>
        ) : (
          <CsButton
            disabled={!(sgChecked && tosChecked)}
            loading={loading}
            onClick={handleSubmit(onSubmit)}
          >
            {t(TEXT.KYC_ACCEPT)}
          </CsButton>
        )}
      </Form>
    </div>
  );
};
