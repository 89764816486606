import React, { useState, useRef } from "react";
import { ArrowDownIcon } from "Icons";
import useOutsideClick from "hooks/useOutsideClick";

import "./style.scss";

interface Props {
  label: string;
  options: Array<string>;
  placeholder: string;
  onChange?: (valueSelected: string) => void;
}

export const Dropdown = ({ placeholder, label, options, onChange }: Props) => {
  const [selected, setValue] = useState<string | null>(null);
  const [show, setShow] = useState(false);
  const dropdownRef = useRef(null);

  const handleClose = () => {
    setShow(false);
  }

  useOutsideClick(dropdownRef, handleClose);

  const handleSelect = (item: string) => {
    setValue(item)
    setShow(false);
    onChange && onChange(item);
  }

  return (
    <div
      className="dropdown-component-wrap"
      ref={dropdownRef}
      >
      <button
        onClick={() => setShow(!show)}
        >
        <div className="dropdown-component-value-wrap ellipsis">
          <label>{label}</label>
          <span className="dropdown-component-value ellipsis">{selected ? selected : placeholder}</span>
        </div>
        <ArrowDownIcon />
      </button>
      {show && (
        <div className="dropdown-component-content">
          {options.map((item, index) => (
            <div
              key={`dropdown-component-option${index}`}
              onClick={() => handleSelect(item)}
              className="dropdown-component-option"
            >
              {item}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
