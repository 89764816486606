import React, { useEffect } from "react";
import "./style.scss";
import { setDynamicHeight } from "utils";

export const UnavailableView = () => {
  useEffect(() => setDynamicHeight(410), [setDynamicHeight]);
  return (
    <div className="under-maintenance">
    </div>
  );
};
