import { CustomStylesConfig } from "types";

export enum THEME {
  BLUE = "blue",
  LIGHT = "light",
  DEFAULT = "default", // changing default to light
  DARK = "dark",
}

export const THEME_BACKGROUNDS = {
  [THEME.DEFAULT]: "#1c2331",
  [THEME.LIGHT]: "#FFF",
  [THEME.DARK]: "#1c2331",
  [THEME.BLUE]: "#00145c",
};

// init css in file src/scss/default-theme.scss
export const THEMES: { [theme in THEME]: CustomStylesConfig } = {
  [THEME.DEFAULT]: {
    // undocumented feature
    // "cs-special-subtext": {
    //   color: "#666",
    // },
  },
  [THEME.LIGHT]: {},
  [THEME.DARK]: {},
  [THEME.BLUE]: {},
};
