import { SingpassActionTypes, SingpassState, SINGPASS_ACTIONS } from "./types";

const defaultState: SingpassState = {
  current: null,
  fetching: false,
};

export const singpassReducer = (
  state: SingpassState = defaultState,
  action: SingpassActionTypes
): SingpassState => {
  switch (action.type) {
    case SINGPASS_ACTIONS.REQUEST_SINGPASS_CACHE:
      return {
        ...state,
        fetching: true,
      };

    case SINGPASS_ACTIONS.RECEIVE_SINGPASS_CACHE:
      return {
        ...state,
        fetching: false,
        current: action.payload,
      };
    default:
      return state;
  }
};
