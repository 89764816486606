import {
  CUSTOM_STYLES,
  CustomStylesConfig,
  CustomAttributesConfig,
} from "../types";

export const mapCustomStyles = (
  rootClass: string,
  csc: CustomStylesConfig
): any => {
  let r: any = {};
  for (const s of CUSTOM_STYLES) {
    r[`.${rootClass} .${s}`] = mapCustomAttributes(csc[s]);
  }

  const special = csc["cs-special"];
  if (special !== undefined) {
    const borderColor = special["border-color"];
    if (borderColor !== undefined) {
      r[`.${rootClass} .cs-fees`] = {
        "border-left": "1px solid " + borderColor,
      };
      r[`.${rootClass} .cs-fees .cs-icon`] = {
        "background-color": borderColor,
      };
    }
  }

  const subtext = csc["cs-subtext"];
  if (subtext !== undefined) {
    r[`.${rootClass} ::-webkit-input-placeholder`] = mapCustomAttributes(
      subtext
    );
    r[`.${rootClass} :-moz-placeholder`] = mapCustomAttributes(subtext);
    r[`.${rootClass} ::-moz-placeholder`] = mapCustomAttributes(subtext);
    r[`.${rootClass} :-ms-input-placeholder`] = mapCustomAttributes(subtext);
  }

  const specialSubtext = csc["cs-special-subtext"];
  if (specialSubtext !== undefined) {
    r[`.${rootClass} .cs-special .cs-special-subtext`] = mapCustomAttributes(
      specialSubtext
    );
  }

  const error = csc["cs-error"];
  if (error !== undefined) {
    r[`.${rootClass} .cs-base .cs-error`] = mapCustomAttributes(error);
  }

  return r;
};

export const mapCustomAttributes = (
  s?: CustomAttributesConfig
): any | undefined => {
  if (s === undefined) {
    return undefined;
  }
  return {
    background: s.background,
    "border-color": s["border-color"],
    color: s.color,
  };
};

export const styleObjToCss = (styleObj: any) => {
  let s = "";
  for (const c of Object.keys(styleObj)) {
    if (styleObj[c] !== undefined && styleObj[c] !== null) {
      s += `
      ${c} {
        ${mapAttributesToString(styleObj[c])}
      }
    `;
    }
  }
  return s;
};

export const mapAttributesToString = (attributes: any) => {
  return Object.keys(attributes)
    .map((a) =>
      attributes[a] !== undefined
        ? `    ${a}: ${processAttribute(a, attributes[a])};`
        : ""
    )
    .join("\n");
};

export const processAttribute = (name: string, value: string) => {
  return value;
};
