import { TEXT } from "../const";
import React from "react";
import { isConstructorDeclaration } from "typescript";
type Translations = {
  [key in TEXT]?: string;
};

let translations: Translations = {};
let overrideTranslations: Translations = {};

const interpolate = (str: string, vars: { [key: string]: string }) => {
  for (const k of Object.keys(vars)) {
    str = str.replace(`{{${k}}}`, vars[k]);
  }
  return str;
};

/**
 * interpolateComponents
 *
 * Semi-recursive function to parse a string and replace strings with JSX.Element (or string).
 *
 * @param str raw string to be interpolated
 * @param vars map of key to element
 * @returns Array of strings or JSX.Element which should be able to be printed directly to JSX
 */
export const interpolateComponents = (
  str: string,
  vars: { [key: string]: string | JSX.Element }
): Array<JSX.Element | string> => {
  vars["{{nbsp}}"] = String.fromCharCode(160);
  vars["{{br}}"] = <br />;
  let elements: Array<JSX.Element | string> = addLineBreaks(str);
  for (const k of Object.keys(vars)) {
    for (let i = 0; i < elements.length && i < 10; i++) {
      const substr = elements[i];
      if (typeof substr === "string") {
        const parts = substr
          .split(new RegExp(`(${k})`, "g"))
          .filter((p) => p.trim() !== "");
        //console.log({ parts });
        if (parts.length > 1) {
          elements = [
            ...elements.slice(0, i),
            ...parts.map((p) => (p === k ? vars[k] : p)),
            ...elements.slice(i + 1),
          ];
        }
      }
    }
  }
  for (let i = 0; i < elements.length && i < 10; i++) {
    const e = elements[0];
    if (typeof e === "string") {
      const s = formatString(e);
      if (s.length > 1) {
        elements = [...elements.slice(0, i), ...s, ...elements.slice(i + 1)];
        i += s.length - 1;
      }
    }
  }
  return elements;
};

const formatString = (str: string): Array<JSX.Element | string> => {
  const first = str.indexOf("_");
  if (first !== -1) {
    const next = str.indexOf("_", first + 1);
    if (next !== -1) {
      const s = [
        str.substring(0, first),
        <i>{str.substring(first + 1, next)}</i>,
        str.substring(next + 1),
      ];
      return s;
    }
  }
  return [str];
};

const addLineBreaks = (str: string): Array<JSX.Element | string> => {
  const lines = str.split("\n");
  const retval: Array<JSX.Element | string> = [];
  for (const line of lines) {
    if (retval.length > 0) {
      retval.push(<br />);
    }
    retval.push(line);
  }
  return retval;
};

export const setTranslations = (_translations: Translations) => {
  translations = _translations;
};
export const setOverrideTranslations = (_translations: Translations) => {
  overrideTranslations = _translations;
};
export const t = (
  key: TEXT,
  fallback?: string,
  data?: { [key: string]: string }
): string => {
  const override = overrideTranslations[key];
  if (override !== undefined) {
    return override;
  }
  const translation = translations[key];
  if (translation !== undefined) {
    if (data !== undefined) {
      return interpolate(translation, data);
    }
    return translation;
  }
  if (fallback !== undefined) {
    return fallback;
  }
  return key;
};

export const fetchTranslations = (userLocale: string) => {
  return fetch(`https://locale.liquid.com/${userLocale}/qex-widget.json`)
    .then((response) => response.json())
    .then((data) => setTranslations(data));
};

let lang = "en";
export const setLang = (_lang: string) => {
  lang = _lang;
};
export const getLang = () => {
  return lang;
};
