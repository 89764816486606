import { THEME } from "../../const";
import { RenderViewconfig, CustomStylesConfig } from "types";
import { InitializationConfig_Latest } from "../../types";

export enum CONFIGURATION_ACTIONS {
  INITIALIZE = "initialize",
  SET_CONFIGURATION = "set_configuration",
  SET_MINIMAL_CONFIG = "set_minimal_config",
  SET_CUSTOM_STYLES = "set_custom_styles",
  SET_FUNDING_SETTLEMENT = "set_funding_settlement",
  SET_PAYOUT_SETTLEMENT = "set_payout_settlement",
  SET_TERMS_OF_SERVICE = "set_terms_of_service",
  SET_FORM_STATE = "set_form_state",
}

export interface SetConfigurationState {
  type: CONFIGURATION_ACTIONS.SET_CONFIGURATION;
  payload: ConfigurationState;
}

export interface SetMinimalConfig {
  type: CONFIGURATION_ACTIONS.SET_MINIMAL_CONFIG;
  payload: InitializationConfig_Latest;
}

export interface SetCustomStyles {
  type: CONFIGURATION_ACTIONS.SET_CUSTOM_STYLES;
  payload: CustomStylesConfig;
}

export interface UserSessionToken {
  session_id: string;
  session_secret?: string;
}

export interface UserLiquidToken {
  liquid_user_jwt: string;
}

export type ConfigurationIdentity = UserSessionToken | UserLiquidToken;

export interface ConfigurationState {
  theme: THEME;
  legacyFeatures: boolean;
  publicApiKey?: string;
  allow_trade: boolean;
  user_locale: string;
  renderViews: RenderViewconfig;
  custom_styles: CustomStylesConfig;
  verboseLogging: boolean;
  identity?: ConfigurationIdentity;
  surpress_sandbox_warning?: boolean;
  initialization_payload_raw?: any;
  special_layout?: string;
  external_session: boolean;
  intent_id?: string;

  partner_order_id?: string;
  partner_ref?: string;
  partner_tags?: Array<string>;
  affiliate_id?: string;
  affiliate_ref?: string;
  affiliate_tags?: Array<string>;
}

export type ConfigurationActionTypes =
  | SetConfigurationState
  | SetMinimalConfig
  | SetCustomStyles;
