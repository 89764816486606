import { Form } from "react-bootstrap";
import "./style.scss";

export interface CheckboxProps {
  text: string | JSX.Element;
  value: boolean;
  onChange: (val: boolean) => void;
  id: string;
}

export const Checkbox = (props: CheckboxProps) => {
  const onChange = () => {
    console.log(`${props.text} ${!props.value}`);
    props.onChange(!props.value);
  };
  return (
    <label className="checkbox">
      <Form.Check
        // type="switch"
        id={props.id}
        >
        <Form.Check.Input
          type={"checkbox"}
          onChange={onChange}
          value={props.value ? "checked" : "unchecked"}
        />
        <span className="checkmark"></span>
        <span>{props.text}</span>
      </Form.Check>
    </label>
  );
};
