import { SETTLEMENT_METHOD } from "../const";

export enum CURRENCY_SCHEME {
  CRYPTO = "CRYPTO",
  FIAT = "FIAT",
}
export interface Currency {
  label: string;
  short: string;
  type: string;
}
export interface ISettlement {
  funding: SETTLEMENT_METHOD[];
  payout: SETTLEMENT_METHOD[];
}

export interface ICurrencyItem {
  label: string;
  longName?: string;
  type: CURRENCY_SCHEME;
  symbol: string;
  icon: string;
  ccy_code: string;
  settlement: ISettlement;
  approximate_usd_rate?: string;
  suggested_default_quantity?: string;
}

export interface ICurrencyGroup {
  label: string;
  options: ICurrencyItem[];
}

export interface IStatus {
  dealable: boolean;
  stale: boolean;
  tooltipMessage: string | null;
}
