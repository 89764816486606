import { KYC_DOCUMENT_STATUS, KYC_ELEMENT } from "const";
import React, { useEffect } from "react";
import { useState } from "react";
import { KycState } from "store";

import "./style.scss";

interface KycSingpassProps {
  sessionId: string;
  kycState: KycState;
  onBack: () => void;
}

export const KycSingpassView = ({
  sessionId,
  kycState,
  onBack,
}: KycSingpassProps) => {
  const [a, setA] = useState("");
  useEffect(() => {}, [sessionId]);
  const identityStatus = kycState.documents[KYC_ELEMENT.LIVENESS].status;

  useEffect(() => {
    console.log({ identityStatus });
    if (identityStatus === KYC_DOCUMENT_STATUS.APPROVED) {
      onBack();
    }
  }, [identityStatus]);

  return (
    <div className="kyc-singpass-view">
      <h3>Please complete singpass in the new tab</h3>
    </div>
  );
};
